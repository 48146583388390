.da-chart-col-legend{
  width: 100%;
  display: flex;
  flex-wrap:wrap;
  .da-chart-series-color {
    display: inline-block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    margin-left: 10px;
  }
}

/*Charts in Table based on dx grid charts*/
g.dxc-arg-line path {
  stroke: transparent;
}
g.dxc-val-line path {
  stroke: transparent;
}



.da-table {
  width: 100%;
  max-width: 100%;

  thead tr {
    background-color: $sv-color-primary10;
    font-size: 1rem;
  }


  th,
  td {
    padding: .25rem .5rem;
  }

  tbody td {
    border-bottom: 1px solid $sv-color-primary10;
  }

  .da-table-value {
    text-align: center;
  }

  .da-summary {
    border-bottom: 0;
    background-color: $sv-color-primary10;
    font-weight: bold;
  }

  &.da-table-more {
    margin-top: 1rem;

    tbody td {
      border-bottom: 0;
      background-color: $sv-color-primary10;
      font-weight: bold;
    }
  }
}

.da-multi-tabs {
  @extend .row;

  .da-tab {
    @extend .col;
    @extend .flex-wrap;
    padding: .25rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-top: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
    border-color: $sv-color-primary10;
    cursor: pointer;
    display: flex;
    align-content: center;

    &:not(.da-tab-active):hover {
      background-color: $sv-color-primary10;
      transition: background-color 0.25s ease;
      box-shadow: 0px -1px 2px 0px $sv-color-primary-dark;
    }

    &.da-tab-active {
      background-color: $primary;
      color: $sv-color-neutral;
      border-color: $primary;
      transition: background-color 0.25s ease;
      box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.75);
    }
  }

  @media (min-width: 1200px) {
    & {
      display: none !important;
    }

  }
}

.da-multi-container-row {
  @extend .row;
  border-top: 1px solid $primary;

  @media (min-width: 1200px) {
    & {
      border: 0;
    }

  }
}

.da-multi-table {
  @extend .col-12;
  @extend .col-xl-6;
  @extend .p-0;
  @extend .p-xl-4;
  display: none;

  &.da-table-active {
    display: block;
  }

  @media (min-width: 1200px) {
    & {
      margin-top: 1rem;
      border: 0;
      display: block;
    }

  }
}

.dx-datagrid {
  .dx-datagrid-pager {
    border-top: 0;
  }

  .dx-row>td.da-grid-cell {
    padding-left: .5rem;
    padding-right: .5rem;
    font-weight: normal;
    vertical-align: middle !important;
    white-space: nowrap;
    font-size: 1rem;
    font-family: $font-family-sans-serif;
    color: $sv-color-dark;

    &.da-grid-small-cell {
      background-color: $sv-color-secondary10;
    }


  }


  .dx-row>td.da-grid-rowheader-cell {
    background-color: $sv-color-sub-nav-bg;
    border-bottom: 1px solid $sv-color-neutral;

    .dx-datagrid-text-content {
      white-space: nowrap;
    }
  }

  .dx-row.dx-selection>td.da-grid-cell.da-grid-small-cell {

    background-color: transparent;
  }

  .dx-row.dx-selection.dx-state-hover>td.da-grid-cell.da-grid-small-cell {

    background-color: $sv-color-primary10;
    cursor: initial;
  }

  .dx-row-lines {
    &>td.da-grid-cell {
      border-bottom: 1px solid $sv-color-sub-nav-bg;
    }
  }

  .dx-datagrid-headers {
    border-bottom: 1px solid $sv-color-sub-nav-bg;

    .dx-header-row {
      background-color: $sv-color-sub-nav-bg;

      td {
        &.dx-datagrid-action {
          &:hover {
            background-color: $sv-color-primary10;
          }
        }

        &.da-grid-cell {
          background-color: $sv-color-sub-nav-bg;
          border-bottom: 1px solid $sv-color-sub-nav-bg;

          &[aria-sort="ascending"],
          &[aria-sort="descending"] {
            position: relative;
            background-color: $sv-color-primary20;

            &:hover {
              background-color: $sv-color-primary10;
            }
          }
          .dx-datagrid-text-content {
            white-space: nowrap;
          }
        }
      }
    }

    &+.dx-datagrid-rowsview {

      border-top: 1px solid $sv-color-sub-nav-bg;
    }

    &.dx-state-focused {
      .dx-header-row {
        td.da-grid-cell {
          &:focus {
            outline: none;
            outline-offset: 0px;
          }

          .dx-header-filter:focus {
            outline: none;
            outline-offset: 0px;
          }
        }
      }
    }
  }

  .dx-datagrid-table {
    .dx-data-row {
      &.dx-state-hover {
        &:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) {
          &>td:not(.dx-focused) {
            background-color: $sv-color-primary10;
            cursor: pointer;
          }
        }
      }
    }
  }
}