$table-border-width: 1px !default;

$table-hover-bg: $sv-color-primary10 !default;

table.table {
    border: none;
    color: $sv-color-dark;

    td,
    th {
        height: 3.75rem;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        font-weight: normal;
        vertical-align: middle !important;
        max-width: 500px;
        @extend .text-truncate;
    }

    thead {
        th {
            background-color: $sv-color-sub-nav-bg;
            border: none;
        }
    }

    tbody {
        border: none;

        td,
        th {
            border-top: none;
            border-bottom: $table-border-width solid $sv-color-sub-nav-bg;
        }

        th {
            font-weight: bold;
        }
    }
}

table.sv-table {
    @extend .table;

    tbody {

        td,
        th {
            font-weight: normal;

            &.sv-table-controls {
                text-align: right;
                min-width: 160px;
            }
        }

        tr {
            &:hover {
                background-color: $table-hover-bg;
            }
        }
    }
}

.sv-table-hover {
    @extend .sv-table,
    .table-hover;
}

.sv-table-hover,
.sv-table {
    thead {
        th {
            white-space: nowrap;
            position: relative;

            &:hover:not(.sv-sort-none) {
                background-color: $sv-color-primary10 !important;
                cursor: pointer;
            }
        }

        th.sv-nowidth {
            width: inherit;
        }
    }

    tbody {
        tr {
            cursor: pointer;

            td {
                position: relative;
            }
        }
    }


}

.sv-sort-ascent,
.sv-sort-descent {
    background-color: $sv-color-primary20 !important;

    &:hover {
        background-color: $sv-color-primary10 !important;
    }
}

.sv-sort-ascent:after {
    @include sv-fontawesome(" \f106");
    position: absolute;
    color: $sv-color-secondary40;
    right: 0.625rem;
}

.sv-sort-descent:after {
    @include sv-fontawesome(" \f107");
    position: absolute;
    color: $sv-color-secondary40;
    right: 0.625rem;
}

.sv-table-container {
    @extend .sv-main-layout-row;
    position: relative;

    .sv-pagination-container {
        display: flex;
        justify-content: center;
        margin: 2.75rem 0;
    }
}

.sv-status {
    font-weight: bold;
    padding: 0.3125rem 0.9375rem;

    &.sv-on {
        background-color: $success;
        color: $sv-color-neutral;
        border-radius: 0.1875rem;
    }
}

.sv-date-cell {
    text-align: center;
}

.sv-col-1 {
    width: 8.33%;
}

.sv-col-2 {
    width: 16.66%;
}

.sv-col-3 {
    width: 25%;
}

.sv-col-4 {
    width: 33.33%;
}

.sv-col-5 {
    width: 41.66%;
}

.sv-col-6 {
    width: 50%;
}

.sv-col-7 {
    width: 58.33%;
}

.sv-col-8 {
    width: 66.66%;
}

.sv-col-9 {
    width: 75%;
}

.sv-col-10 {
    width: 83.33%;
}

.sv-col-11 {
    width: 91.66%;
}

.sv-col-12 {
    width: 100%;
}

.sv-placeholder-tree-sidebar {
    .dx-treelist-header-panel {
        background-color: $sv-color-primary20;
    }

    .dx-toolbar .dx-toolbar-after {
        width: 100%;
        padding-left: 0;
    }

    .dx-treelist-search-panel {
        margin-left: 0;
    }
}