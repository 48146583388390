.sv-filter-controls {
  min-width: 110px;
  max-width: 110px !important;
  text-align: center;
  line-height: 34px;
}

.sv-filter-statement {
  text-align: center;
  line-height: 34px;
  font-size: 1.1rem;
}

.sv-filter-calculation-textarea {
  min-height: calc(1rem + 2px);
}

.sv-filter-row {
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
  @extend .align-items-center;
  @extend .mb-1;
}

.sv-filter-row-seperated {
  padding-top: .75rem !important;
  border-top: 1px solid $sv-color-secondary40;
  margin-top: 0.5rem;
}