.modal-dialog {
  .modal-content {
    border-radius: 0.625rem;
  }
}

.sv-modal-warning-msg {
  color: $danger;
  text-align: center;
  @extend .pt-2;
}