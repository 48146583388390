// Dev Extreme Design Definitions
body {

  .dx-popup-content {
    padding: 2px;
  }

  .dx-numberbox {

    .dx-texteditor-input {
      text-align: right;
    }

    .dx-placeholder {
      left: 10px;
      right: 0;
      font-size: 1rem;
      text-align: right;
    }
  }

  .dx-autocomplete {
    .dx-placeholder {
      left: 0;
    }
  }

  .dx-textbox,
  .dx-numberbox {
    &.dx-texteditor.dx-editor-outlined {
      border: 1px solid $sv-color-secondary20;
      border-radius: 0;

      &.dx-state-focused,
      &.dx-state-active {
        border-color: $sv-color-dark;
      }

    }

    input.dx-texteditor-input {
      height: calc(1.5em + 0.5rem + 2px);
      font-size: 1rem;
      line-height: 1.5;
      min-height: auto;
      font-family: $font-family-sans-serif;
    }

    &:not(.dx-searchbox):not(.dx-colorbox):not(.dx-autocomplete) {

      input.dx-texteditor-input {
        padding: 0.25rem 0.5rem;
      }
    }

    .dx-button-content {
      div[class*="icon"] {
        border-radius: 0;
      }
    }
  }

  .dx-tag-container {
    &.dx-texteditor-input-container {
      padding-bottom: 0;
    }
  }

  .sv-upload-manager.dx-filemanager {
    height: calc(100vh - 80px);
  }

  .sv-photo-popup-content {
    text-align: center;
  }

  .sv-photo-popup-content .sv-photo-popup-image {
    height: 100%;
    max-width: 100%;
  }

  .dx-checkbox-container {
    padding: 1px;

    .dx-checkbox-icon {
      width: 1.25rem;
      height: 1.25rem;
      border-color: $sv-color-dark;
      border-width: 2px;
      border-radius: 0;

      &:before {
        font-size: 0.6rem;
        position: relative;
        display: block;
        width: 100%;
        text-align: center;
        line-height: 1rem;
        top: auto;
        margin-top: auto;
        left: auto;
        margin-left: auto;
      }
    }
  }
}

.dx-state-hover {
  .dx-checkbox-container {

    .dx-checkbox-icon {
      border-color: $sv-color-dark;
      border-width: 2px;

      &:before {
        color: $success;
        @include sv-fontawesome(" \f00c");
      }
    }
  }

  .dx-checkbox-checked,
  .dx-checkbox-checked.dx-state-focused {
    .dx-checkbox-container {

      .dx-checkbox-icon {
        border-color: $success;
        background-color: $success;
        border-width: 2px;

        &:before {
          @include sv-fontawesome(" \f00c");
          color: $sv-color-neutral;
        }
      }
    }
  }
}

.dx-checkbox.dx-state-focused .dx-checkbox-icon {
  border-color: $success;
}

.dx-state-focused:not(.dx-state-hover):not(.dx-checkbox-checked) {
  .dx-checkbox-container {

    .dx-checkbox-icon {
      border-color: $sv-color-dark;
      border-width: 2px;

      &:before {
        color: $success;
        content: '';
      }
    }
  }

  .dx-checkbox-checked,
  .dx-checkbox-checked.dx-state-focused {
    .dx-checkbox-container {

      .dx-checkbox-icon {
        border-color: $success !important;
        background-color: $success;
        border-width: 2px;

        &:before {
          @include sv-fontawesome(" \f00c");
          color: $sv-color-neutral;
        }
      }
    }
  }
}

.dx-checkbox-checked {
  .dx-checkbox-container {

    .dx-checkbox-icon {
      border-color: $success;
      background-color: $success;
      border-width: 2px;

      &:before {
        color: $sv-color-neutral;
        @include sv-fontawesome(" \f00c");
      }
    }
  }
}

.dx-checkbox.dx-checkbox-indeterminate .dx-checkbox-container .dx-checkbox-icon:before {
  @include sv-fontawesome(" \f04d");
  color: $success;
}

.dx-list.dx-list-select-decorator-enabled .dx-list-item.dx-state-hover.dx-state-focused .dx-checkbox-checked .dx-checkbox-icon,
.dx-list.dx-list-select-decorator-enabled .dx-list-item.dx-state-focused .dx-checkbox-checked .dx-checkbox-icon,
.dx-list.dx-list-select-decorator-enabled .dx-list-item.dx-state-hover .dx-checkbox-checked .dx-checkbox-icon {
  border-color: $success;
}

.dx-list.dx-list-select-decorator-enabled .dx-list-item.dx-state-focused .dx-checkbox:not(.dx-checkbox-checked) .dx-checkbox-icon,
.dx-list.dx-list-select-decorator-enabled .dx-list-item.dx-state-hover .dx-checkbox:not(.dx-checkbox-checked) .dx-checkbox-icon {
  border: 2px solid $sv-color-dark;
}

.dx-overlay-content .dx-calendar-navigator .dx-calendar-caption-button.dx-button .dx-button-content,
.dx-overlay-content .dx-calendar-navigator-next-view.dx-button .dx-icon,
.dx-overlay-content .dx-calendar-navigator-next-month.dx-button .dx-icon,
.dx-overlay-content .dx-calendar-navigator-previous-view.dx-button .dx-icon,
.dx-overlay-content .dx-calendar-navigator-previous-month.dx-button .dx-icon {
  color: $primary;
}

.dx-overlay-content .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-contoured-date,
.dx-overlay-content .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today.dx-calendar-contoured-date {
  box-shadow: inset 0 0 0 1px $sv-color-secondary20, inset 0 0 0 1000px $success;
}

.dx-overlay-content .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-active,
.dx-overlay-content .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-list-item-selected {
  background-color: $success;
  color: $sv-color-neutral;
}

.sv-slider-container {
  .dx-slider {

    .dx-slider-handle,
    .dx-state-hover.dx-slider-handle:not(.dx-state-active),
    .dx-state-active.dx-slider-handle,
    .dx-state-focused.dx-slider-handle:not(.dx-state-active) {
      margin-top: -13px;
      margin-right: -13px;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background-image: linear-gradient(45deg, $sv-color-neutral 0%, $sv-color-secondary10 50%);
      border: 1px solid $sv-color-secondary40;
    }

    .dx-trackbar-range.dx-slider-range.dx-slider-range-visible {
      height: 8px;
      border-radius: 4px;
      background-color: $success;
      border-color: $sv-color-secondary40;
    }

    .dx-slider-bar {
      margin: 14px;
      height: 8px;
      border-radius: 4px;
      background-color: $sv-color-dark;
      border-color: $sv-color-dark;
    }
  }
}



// Table Grid Design

.dx-datagrid {
  .dx-datagrid-pager {
    border-top: 0;
  }

  .dx-row>td.sv-grid-cell {

    height: 3.75rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    font-weight: normal;
    vertical-align: middle !important;
    white-space: nowrap;
    font-size: 1rem;
    font-family: $font-family-sans-serif;
    color: $sv-color-dark;

    &.sv-grid-small-cell {
      height: 2.5rem;
      background-color: $sv-color-secondary10;
    }
  }

  .dx-row.dx-selection>td.sv-grid-cell.sv-grid-small-cell {

    background-color: transparent;
  }

  .dx-row.dx-selection.dx-state-hover>td.sv-grid-cell.sv-grid-small-cell {

    background-color: $sv-color-primary10;
    cursor: initial;
  }

  .dx-row-lines {
    &>td.sv-grid-cell {
      border-bottom: 1px solid $sv-color-sub-nav-bg;
    }
  }

  .dx-datagrid-headers {
    border-bottom: 1px solid $sv-color-sub-nav-bg;

    .dx-header-row {
      background-color: $sv-color-sub-nav-bg;

      td {
        &.dx-datagrid-action {
          &:hover {
            background-color: $sv-color-primary10;
          }
        }

        &.sv-grid-cell {
          background-color: $sv-color-sub-nav-bg;
          border-bottom: 1px solid $sv-color-sub-nav-bg;

          &.sv-grid-small-cell {
            height: 3.75rem;
          }

          &[aria-sort="ascending"],
          &[aria-sort="descending"] {
            position: relative;
            background-color: $sv-color-primary20;

            &:hover {
              background-color: $sv-color-primary10;
            }

            .dx-column-indicators {
              display: none;
            }
          }

          &[aria-sort="ascending"] {
            &:after {
              @include sv-fontawesome(" \f106");
              position: absolute;
              color: $sv-color-secondary40;
              right: 0.625rem;
            }
          }

          &[aria-sort="descending"] {
            &:after {
              @include sv-fontawesome(" \f107");
              position: absolute;
              color: $sv-color-secondary40;
              right: 0.625rem;
            }
          }

          .dx-datagrid-text-content {
            white-space: nowrap;
          }
        }
      }
    }

    &+.dx-datagrid-rowsview {

      border-top: 1px solid $sv-color-sub-nav-bg;
    }

    &.dx-state-focused {
      .dx-header-row {
        td.sv-grid-cell {
          &:focus {
            outline: none;
            outline-offset: 0px;
          }

          .dx-header-filter:focus {
            outline: none;
            outline-offset: 0px;
          }
        }
      }
    }
  }

  .dx-datagrid-table {
    .dx-data-row {
      &.dx-state-hover {
        &:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) {
          &>td:not(.dx-focused) {
            background-color: $sv-color-primary10;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.sv-recipients-view {

  .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused).dx-row-lines>td,
  .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused).dx-row-lines>tr>td,
  .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover.dx-row-lines>td,
  .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover.dx-row-lines>tr>td {
    background-color: transparent;
  }

  .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-select {

    background-color: $sv-color-primary10;
  }
}


//File Upload

.dx-fileuploader-wrapper {
  padding: 0;

  .dx-fileuploader-input-wrapper {
    position: relative;
    padding: 0;
    border: none;

    &:before,
    &:after {
      display: none;
    }

    .dx-button {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
    }

    .dx-fileuploader-input-container {
      display: block;
      height: 11.25rem;
      background-color: $sv-color-grey8;
      border: 0.0625rem dashed $sv-color-secondary60;

      .dx-fileuploader-input-label {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.sv-datagrid-dropdown-select .sv-dropdown-group-header td {
  font-weight: 700;
  padding: 20px 10px 10px;
  border-bottom: 2px solid $sv-color-secondary20;
  background: $sv-color-neutral;
  color: $secondary;
}

// translation page
.dx-dropdowneditor-overlay .dx-list-item-selected .sv-icon .text-success {
  color: $sv-color-dark !important;
}

// Master Slave Grid
.dx-datagrid .dx-datagrid-rowsview .dx-row>.dx-master-detail-cell {
  padding: 5px;
}

.sv-datagrid-dropdown-select .dx-toolbar .dx-texteditor {
  width: 100% !important;
}

.sv-datagrid-dropdown-select .dx-toolbar .dx-toolbar-after {
  padding-left: 0px;
  width: 100% !important;
}

.sv-datagrid-dropdown-select .dx-datagrid-search-panel {
  margin-left: 0px;
}

.sv-workspace-list {

  .dx-toolbar .dx-texteditor,
  .dx-toolbar-after {
    width: 100% !important;
  }

  .dx-toolbar .dx-toolbar-after {
    padding-left: 0px;
  }

  .dx-datagrid-search-panel {
    margin-left: 0;
  }
}