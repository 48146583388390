.aui-reusable-list{
    display: flex;
  }
  .aui-list-controls{
    width: 60px;
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    justify-items: stretch;
  }
  .aui-list-items{
    flex-grow: 1;
  }
  .aui-list-controls-top, .aui-list-controls-bottom{
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    height: 100%;
    padding-right: 10px;
  }
  .aui-list-controls-menu {
    display: flex;
    padding: 5px;
    align-items: center;
  }
  .aui-list-name{
    white-space: nowrap;
    writing-mode:vertical-rl;
    transform: rotate(-180deg);
    text-align: center;
  }
  .aui-list-menu-icons {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 10px;
  }
  .aui-list-controls-top:before{
    content: ' ';
    height: 100%;
    width: 50%;
    border-top: 1px solid #b4b4b4;
    border-left: 1px solid #b4b4b4;
    margin-top: 0.5rem;
    border-top-left-radius: 4px;
  }
  .aui-list-controls-bottom {
    padding-bottom: 0.5rem;
  }
  .aui-list-controls-bottom:before{
    content: ' ';
    height: 100%;
    width: 50%;
    border-bottom: 1px solid #b4b4b4;
    border-left: 1px solid #b4b4b4;
    border-bottom-left-radius: 4px;
  }