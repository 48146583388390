div:not(.sv-sub-process) {
  .sv-process-container {
    .sv-process-step {
      @extend .flex-fill;
    }
  }
}

.sv-process-connector {
  margin-right: 2.15rem;
  margin-left: 3px;
  border-top: 0.5rem solid $primary;
  border-right: 0.225rem solid transparent;
  border-left: 0.225rem solid transparent;
  height: 0;
}

.sv-process-container {
  padding-right: 2rem;
  @extend .d-flex;
  @extend .flex-wrap;



  .sv-process-step-empty {
    max-width: 0px;
    width: 0px;

    .sv-process-step-arrow {
      &:after {
        border-left-color: $sv-color-neutral !important;
      }
    }
  }


  .sv-process-step {
    @extend .text-center;
    position: relative;

    &:first-child {
      .sv-process-step-text {
        padding: 0.25rem 1rem 0.25rem 1rem;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }

    .sv-process-step-arrow {
      display: inline-block;
      border-top: 29px solid transparent;
      border-bottom: 29px solid transparent;
      border-left: 1rem solid $sv-color-neutral;
      height: 2.55rem;
      width: 1.1rem;
      position: absolute;
      right: -18px;
      top: -0;
      z-index: 1;
      @media (max-width: 1500px){
        height: 40px;
        border-top-width: 20px;
        border-bottom-width: 20px;
      }

      &:after {
        display: inline-block;
        border-top: 29px solid transparent;
        border-bottom: 29px solid transparent;
        border-left: 1rem solid $sv-color-grey8;
        height: 2.55rem;
        width: 1.1rem;
        position: absolute;
        right: 2px;
        top: -29px;
        z-index: 2;
        content: '';
        @media (max-width: 1500px){
          height: 40px;
          top: -20px;
          border-top-width: 20px;
          border-bottom-width: 20px;
        }
      }
    }

    .sv-process-step-text {
      @extend .d-flex;
      @extend .justify-content-center;
      background-color: $sv-color-grey8;
      padding: 0.25rem 1rem 0.25rem 1.5rem;
      border-top: 1px solid $sv-color-neutral;
      border-bottom: 1px solid $sv-color-neutral;
      border-left: 1px solid $sv-color-neutral;
      height: 58px;
      @media (max-width: 1500px){
        height: 40px;
      }
    }

    .sv-process-step-number,
    .sv-process-step-label,
    .sv-process-step-icon {
      @extend .align-self-center;
    }

    .sv-process-step-number {
      display: inline-block;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      background-color: $sv-color-secondary60;
      color: $sv-color-neutral;
      font-size: 0.8rem;
      margin-right: 0.25rem;
      padding-top: 0.2rem;
    }

    .sv-process-step-icon {
      color: $sv-color-secondary60;

    }

    .sv-process-step-label {
      color: $sv-color-secondary60;

      @media (max-width: 1000px) {
        & {
          display: none;
        }
      }
    }

    &.sv-process-step-success {
      &~.sv-process-step-active {
        .sv-process-step-arrow {
          border-left-color: transparent;

          &:after {
            right: 1px;
          }
        }
      }

      &:hover {
        cursor: pointer;

        .sv-process-step-text {
          background-color: $sv-color-primary-dark;
        }

        .sv-process-step-arrow {

          &:after {
            border-left-color: $sv-color-primary-dark;
          }
        }
      }

      .sv-process-step-text {
        background-color: $primary;
        color: $sv-color-neutral;
        border-top: 1px solid $primary;
        border-bottom: 1px solid $primary;
      }

      .sv-process-step-number {
        background-color: $sv-color-neutral;
        color: $primary;
      }

      .sv-process-step-label,
      .sv-process-step-icon {
        color: $sv-color-neutral;
      }

      .sv-process-step-arrow {
        border-left-color: $sv-color-neutral;

        &:after {
          border-left-color: $primary;
        }
      }
    }

    &.sv-process-step-active {
      &~.sv-process-step-success {
        .sv-process-step-arrow {
          border-left-color: transparent;

          &:after {
            right: 1px;
          }
        }
      }

      .sv-process-step-text {
        background-color: $primary;
        color: $sv-color-neutral;
        border-top: 1px solid $primary;
        border-bottom: 1px solid $primary;
      }

      .sv-process-step-number {
        background-color: $sv-color-neutral;
        color: $primary;
      }

      .sv-process-step-label,
      .sv-process-step-icon {
        color: $sv-color-neutral;
      }

      .sv-process-step-arrow {
        border-left-color: $sv-color-neutral;

        &:after {
          border-left-color: $primary;
        }
      }
    }
  }


}

.sv-process-content {
  .sv-cta-drop-down {
    .dx-dropdowneditor-icon {
      @extend .bg-success;
      color: $sv-color-neutral;
    }
  }

  .dx-fileuploader-container {
    @extend .container-fluid;

    .dx-fileuploader-content {
      @extend .row;

      .dx-fileuploader-input-wrapper {
        @extend .col;
      }

      .dx-fileuploader-files-container {
        @extend .col;
      }
    }
  }
}

.sv-sub-process {
  padding-right: 0.65rem;
  position: relative;
  background-color: $primary;
  padding-bottom: 0.525rem;
  padding-left: 0.525rem;
  margin-left: 0.25rem;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 5px;
  @extend .d-inline-block;

  .sv-process-container {
    .sv-process-step-empty {
      .sv-process-step-arrow {
        &:after {
          border-left-color: $sv-color-neutral;
        }
      }
    }

    .sv-process-step {
      &.sv-process-step-active {
        .sv-process-step-text {
          border-top: 0;
        }
      }
    }
  }

  &:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-bottom: 4.17rem solid $sv-color-neutral;
    border-left: 2.25rem solid transparent;
    right: 0px;
    top: 0;
  }
}

.sv-process-close {
  position: fixed;
  top: 2rem;
  right: 2rem;
  color: $success;
  z-index: 2;

  &:hover {
    color: $primary;
  }
}

.sv-process-sticky-steps {
  @extend .container-fluid;
  @extend .sticky-top;
  @extend .bg-white;
  @extend .py-4;
  margin-top: 2rem;
  z-index: 3 !important;


  @media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none) {
    & {
      position: fixed;
      top: 0;
      margin-top: 0 !important;
    }
  }
  @media (max-width: 1500px){

    margin-top: 1rem;
  }
}

.sv-process-heading {
  text-align: center;
  margin-bottom: 1rem;
}

.sv-process-shadow {
  position: fixed;
  top: 105px;
  width: 100%;
  box-shadow: 0px 0.5px 2.5px 0.5px rgba(0, 0, 0, 0.75);
  z-index: 2;

  @media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none) {
    & {
      top: 105px;
    }
  }
  @media (max-width: 1500px){
    top: 90px;
  }

}

.sv-process-container {
  @extend .container;
  @extend .m-auto;
  @extend .pr-5;
}

.sv-process-fixed-footer {
  @extend .container-fluid;
  @extend .px-1;
  @extend .sv-process-fixed-footer;
  @extend .fixed-bottom;
  @extend .bg-primary;
  @extend .align-items-center;
  @extend .d-flex;
  z-index: 900;
}
div.sv-process-fixed-footer {
z-index: 900;
}

.sv-process-fixed-footer-container {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-items-center;
  @extend .container;

  @media (max-width: 1500px){
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
  }
}

.sv-process-content {
  @extend .container;
  padding-bottom: 7rem;

  @media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none) {
    & {
      padding-top: 7rem;
    }
  }
}

.sv-process-nxt-btn,
.sv-process-back-btn {
  @extend .btn;
  white-space: nowrap;
}

.sv-process-nxt-btn {
  @extend .btn-success;
  @extend .ml-2;
}

.sv-process-back-btn {
  @extend .btn-light;
  @extend .mr-2;
}

.sv-process-info-text {
  @extend .text-white;
}