//@import 'colors';

.flex-even {
  flex: 1;
}

// Styles for additional background color definitions
// Primary
.bg-primary-dark {
  background-color: $sv-color-primary-dark;
}

.bg-primary90 {
  background-color: $sv-color-primary90;
}

.bg-primary80 {
  background-color: $sv-color-primary80;
}

.bg-primary60 {
  background-color: $sv-color-primary60;
}

.bg-primary40 {
  background-color: $sv-color-primary40;
}

.bg-primary20 {
  background-color: $sv-color-primary20;
}

.bg-primary10 {
  background-color: $sv-color-primary10;
}

// Secondary
.bg-secondary80 {
  background-color: $sv-color-secondary80;
}

.bg-secondary60 {
  background-color: $sv-color-secondary60;
}

.bg-secondary40 {
  background-color: $sv-color-secondary40;
}

.bg-secondary20 {
  background-color: $sv-color-secondary20;
}

.bg-secondary10 {
  background-color: $sv-color-secondary10;
}

// Success
.bg-success80 {
  background-color: $sv-color-success80;
}

.bg-success60 {
  background-color: $sv-color-success60;
}

.bg-success40 {
  background-color: $sv-color-success40;
}

.bg-success20 {
  background-color: $sv-color-success20;
}

.bg-success10 {
  background-color: $sv-color-success10;
}

// Info
.bg-info80 {
  background-color: $sv-color-info80;
}

.bg-info60 {
  background-color: $sv-color-info60;
}

.bg-info40 {
  background-color: $sv-color-info40;
}

.bg-info20 {
  background-color: $sv-color-info20;
}

.bg-info10 {
  background-color: $sv-color-info10;
}

// Warning
.bg-warning80 {
  background-color: $sv-color-warning80;
}

.bg-warning60 {
  background-color: $sv-color-warning60;
}

.bg-warning40 {
  background-color: $sv-color-warning40;
}

.bg-warning20 {
  background-color: $sv-color-warning20;
}

.bg-warning10 {
  background-color: $sv-color-warning10;
}

// Danger
.bg-danger80 {
  background-color: $sv-color-danger80;
}

.bg-danger60 {
  background-color: $sv-color-danger60;
}

.bg-danger40 {
  background-color: $sv-color-danger40;
}

.bg-danger20 {
  background-color: $sv-color-danger20;
}

.bg-danger10 {
  background-color: $sv-color-danger10;
}

// grey base
.bg-greybase {
  background-color: $sv-color-greybase;
}

.bg-grey18 {
  background-color: $sv-color-grey18;
}

.bg-grey15 {
  background-color: $sv-color-grey15;
}

.bg-grey12 {
  background-color: $sv-color-grey12;
}

.bg-grey8 {
  background-color: $sv-color-grey8;
}

.bg-grey5 {
  background-color: $sv-color-grey5;
}

.bg-grey2 {
  background-color: $sv-color-grey2;
}

.bg-da-first {
  background-color: $sv-color-da-first;
}

.bg-da-second {
  background-color: $sv-color-da-second;
}

.bg-da-third {
  background-color: $sv-color-da-third;
}

.bg-da-fourth {
  background-color: $sv-color-da-fourth;
}

.bg-da-fifth {
  background-color: $sv-color-da-fifth;
}

.bg-da-sixth {
  background-color: $sv-color-da-sixth;
}

.bg-da-bad {
  background-color: $sv-color-da-bad;
}

.bg-da-neutral {
  background-color: $sv-color-da-neutral;
}

.bg-da-good {
  background-color: $sv-color-da-good;
}

//rt-error

.bg-rt-error {
  background-color: $error;
}

// Syntax Highlight Color
.text-orange {
  color: coral;
}

.sv-icon-lg {
  font-size: 1.5rem;

  &:before {
    font-size: 1.5rem;
  }
}

// Icons
.sv-icon {
  color: $sv-color-secondary40;

  &:hover {
    text-decoration: none;
    color: $sv-color-secondary60;
    cursor: pointer;
  }

  &.sv-icon-dark {
    color: $sv-color-secondary80;

    &:hover {
      color: $secondary;
    }
  }

  &.sv-icon-active {
    color: $secondary;
  }

  &.sv-show-color-container {
    width: 2rem;
    height: 2rem;
    color: $sv-color-secondary80;
    display: block;
    text-align: center;

    i {
      display: inline;
    }

    .sv-show-color {
      height: 4px;
      width: 28px;
      border: .0625rem solid $sv-color-secondary80;
      margin: auto;
    }

    &:hover {
      text-decoration: none;
      color: $secondary;

      .sv-show-color {
        border-color: $secondary;
      }
    }
  }
}

.h6 {
  &.sv-setting-header {
    font-weight: bold;
  }
}

.sv-sidebar,
.sv-content-container {
  height: calc(100vh - 9.4rem);
  overflow: auto;
}

.sv-sidebar {
  .btn-primary {
    &~.dropdown-menu {
      .dropdown-item {
        &:hover {
          background-color: $primary;
          color: $sv-color-neutral;
        }
      }
    }
  }
}

.fas:before {
  font-weight: 700;
}

.sv-sidebar-row {
  @extend .px-4;
  @extend .py-3;
  border-bottom: 1px solid $sv-color-neutral;
  color: $sv-color-dark;

  .bg-light {
    margin-top: 2px;
    background-color: $sv-color-grey2 !important;
  }
}

.sv-sidebar-collapsable {
  .sv-sidebar-collapsable-icon:before {
    @include sv-fontawesome('\f078');
    padding-right: .75rem;
  }

  cursor: pointer;

  &.sv-sidebar-collapsed {

    .sv-sidebar-collapsable-icon:before {
      @include sv-fontawesome('\f054');
    }
  }
}

.sv-filter {
  @extend .bg-info10;
  @extend .container-fluid;
  @extend.p-3;
  font-size: 0.8rem;
  font-weight: bold;

  .sv-filter-row {
    @extend .justify-content-start;
    @extend .py-1;
    @extend .mb-0;
    @extend .row;

    &.sv-filter-row-or {
      @extend .mt-1;
      @extend .pt-2;
      border-top: 1px dotted $sv-color-secondary40;
    }
  }


  .sv-filter-conjunction {
    @extend .text-right;
    color: $sv-color-secondary60;
    max-width: 50px;
    min-width: 50px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .sv-filter-definition {
    @extend .row;
    color: $info;
  }

  .sv-filter-type {
    @extend .text-center;
    color: $info;
    position: relative;
    padding-right: 5px;
    padding-left: 5px;
    max-width: 40px;
    min-width: 40px;
  }

  .sv-filter-condition-variable {
    @extend .px-1;
    @extend .text-truncate;
    color: $sv-color-secondary60;
    max-width: 30%;

  }

  .sv-filter-condition-operator {
    @extend .text-truncate;
    @extend .px-1;
    color: $danger;
    max-width: 15%;
  }

  .sv-filter-condition-qtext {
    @extend .text-truncate;
    @extend .px-1;
    color: $sv-color-info80;
    max-width: 18%;
  }
}

.sv-filter-compact-view {
  padding-left: 1rem;

  &:before {
    @include sv-fontawesome("\f0b0");
    position: relative;
    left: -0.5rem;
    color: $sv-color-info80;
  }

  &:hover {
    &:before {
      color: $info;
    }
  }
}

.sv-end-container {
  position: relative;
  margin-bottom: 3.125rem;
  margin-top: 3.125rem;

  .grey-line {
    border-bottom: 2px solid $sv-color-secondary40;
    margin-bottom: 0.5rem;

    .sv-end-text {
      position: relative;
      background-color: $sv-color-neutral;
      color: $sv-color-dark;
      font-size: .825rem;
      font-weight: bold;
      padding: .875rem 1.75rem;
      top: .7rem;
    }
  }

  >div {
    margin: auto 0;
    font-weight: normal;
    font-size: 1rem;
    color: $sv-color-secondary40;
  }

  .sv-endtext-container {
    border: 1px solid $sv-color-dark;
    font-size: 1rem;
    min-height: 4rem;
  }

}

.sv-drop-container {
  padding: 1rem;
  border: 0.05rem dashed $sv-color-secondary40;
  text-align: center;
  color: $sv-color-secondary40;
  font-size: 0.8rem;
  background-color: $sv-color-warning20;
}

.sv-section {
  margin-bottom: 3rem;

  .sv-section {
    border-right: 2px solid $sv-color-secondary20;
    border-left: 2px solid $sv-color-secondary20;
  }

  .sv-section-head {
    border-top: 2px solid $sv-color-secondary20;
    line-height: 3rem;
    background-color: $sv-color-neutral;

    &:hover {
      background-color: $sv-color-primary10;
      cursor: move;
    }

    .sv-section-head-collapse {
      min-width: 3rem;
    }
  }

  &.sv-section-active {

    >.sv-section-head,
    >.sv-section-content {
      background-color: $sv-color-primary20;
    }
  }

  .sv-section-content {

    .sv-empty-section {
      padding: 5rem;
      border: 0.05rem dashed $sv-color-secondary40;
      text-align: center;
      color: $sv-color-secondary40;
      font-size: 0.8rem;
    }

    .sv-element {
      background-color: $sv-color-background-qt;

      .sv-element-content:has(.rt-matrix-container), 
      .sv-element-content:has(.rt-semantic-container),
      .sv-element-content:has(.rt-nps-container)
       {
        & {
          max-width: 100%;
          overflow: auto;
        }
        .rt-container {
          min-width: 1000px;
        }

      }

      &:hover {
        background-color: $sv-color-primary10;
        cursor: all-scroll;
      }

      &.sv-element-active {
        background-color: $sv-color-primary20;

        .sv-element-head {
          border-bottom: 1px solid $sv-color-neutral;
        }
      }


      .sv-element-head {
        border-bottom: 1px solid $sv-color-secondary20;
        color: $sv-color-secondary40;

        .sv-element-head-text {
          font-size: 0.9rem;
        }

        .sv-element-controls-category {
          border-left: 0.05rem dotted $sv-color-secondary40;
          min-width: 4.075rem;
        }
      }
    }

    .sv-function {
      background-color: $sv-color-neutral;

      &:hover {
        background-color: $sv-color-primary10;
        cursor: move;
      }

      &.sv-function-active {
        background-color: $sv-color-primary20;

        .sv-function-head {
          border-bottom: 2px solid $sv-color-neutral;
        }

        .sv-function-content {
          .sv-function-border {
            border-bottom: 1px solid $sv-color-neutral;
          }
        }
      }

      .sv-function-head {
        border-top: 2px solid $sv-color-secondary20;
        border-bottom: 2px solid $sv-color-secondary20;

        .sv-function-head-text {
          font-size: 0.9rem;
        }
      }

      .sv-function-content {
        .sv-function-border {
          border-bottom: 1px solid $sv-color-secondary20;
        }
      }
    }
  }



  &.sv-section-compact {
    .sv-section-content {

      .sv-empty-section {
        padding: 1rem;
      }

      .sv-element {
        border-top: 1px solid $sv-color-secondary20;

        .sv-element-head {
          border-bottom: 0;

          .sv-element-head-left {
            font-size: 0.9rem;
            max-width: 70%;

            .sv-qtype {
              font-size: 0.75rem;
              line-height: 1.4rem;
              min-width: 9.5rem;
              border-right: 1px dotted $sv-color-secondary40;

            }

            .sv-qtext {
              color: $sv-color-dark;
            }

            @media (max-width: 1200px) {
              .sv-qtype {
                display: none;
              }
            }
          }

          .sv-element-head-right {
            max-width: 30%;

            .sv-element-qcode {
              @extend .text-truncate;
            }
          }

          @media (max-width: 1200px) {
            .sv-element-head-left {
              max-width: 55%;
            }

            .sv-element-head-right {
              max-width: 45%;
            }
          }
        }
      }

      .sv-function {

        .sv-function-head {
          color: $sv-color-secondary40;
          border-top: 1px solid $sv-color-secondary20;
          border-bottom: 0;
        }
      }
    }
  }

  &.sv-screener-section {
    border: 2px solid $sv-color-warning60;

    .sv-section-head {
      border-top-color: $sv-color-warning60;
      background-color: $sv-color-warning20;
    }
  }
}

.sv-da-page-builder {
  .sv-section {
    margin-bottom: 0rem;
  }

  .sv-content-container {
    padding-bottom: 2.5rem;
  }

  .sv-section-content {
    @extend .row;
    margin-right: 0 !important;
    margin-left: 0 !important;
    max-width: 100%;
    overflow-x: auto;
    flex-wrap: nowrap !important;

    .sv-empty-section {
      width: 100%;
    }

    .sv-element {
      @extend .col;
      transition: max-width 1s;

      .sv-element-head {
        transform-origin: 0 50%;
        transition: transform .5s;
        min-width: 400px;

        .sv-icon i {
          transition: transform .5s;
        }
      }

      .sv-element-content {
        opacity: 1;
        transition: opacity .5s .5s;
        margin-bottom: 2rem;
        background-color: $sv-color-neutral;
        height: calc(100% - 5.2rem);

        .da-col {
          height: 100%;

          .da-slot-cont {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
        }
      }

      .sv-element-collapse i {
        font-style: normal;

        &:before {
          @include sv-fontawesome("\f068");
        }

      }

      &.sv-element-collapsed {
        max-width: 45px;
        min-width: 45px;
        transition: max-width .5s;
        overflow: hidden;
        min-height: 425px;

        .sv-element-collapse i {
          &:before {
            @include sv-fontawesome("\f067");
          }
        }

        .sv-element-head {
          transform: rotate(90deg) translate(-11px);
          min-width: 400px;

          .sv-icon i {
            -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
            -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
            -webkit-filter: none;
            filter: none;
          }
        }

        .sv-element-content {
          opacity: 0;
          transition: opacity .5s, max-height 0s .5s;
          max-height: 0;
        }
      }

      &:not(:last-child) {
        border-right: 1px dotted $sv-color-secondary40;
      }
    }
  }
}

.sv-drag-element {

  &.sv-element {
    background-color: $sv-color-neutral;
    border-top: 1px solid $sv-color-secondary20;
    max-width: 50vw;
    width: 100%;

    .sv-element-head {
      color: $sv-color-secondary40;
      border-bottom: 0;

      .sv-element-head-left {
        font-size: 0.9rem;
        max-width: 70%;

        .sv-qtype {
          font-size: 0.75rem;
          line-height: 1.4rem;
          min-width: 9.5rem;
          border-right: 1px dotted $sv-color-secondary40;

        }

        .sv-qtext {
          color: $sv-color-dark;
        }

        @media (max-width: 1200px) {
          .sv-qtype {
            display: none;
          }
        }
      }

      .sv-element-head-right {
        max-width: 30%;

        .sv-element-qcode {
          @extend .text-truncate;
        }
      }

      @media (max-width: 1200px) {
        .sv-element-head-left {
          max-width: 55%;
        }

        .sv-element-head-right {
          max-width: 45%;
        }
      }
    }
  }

  &.sv-function {
    background-color: $sv-color-neutral;
    max-width: 50vw;
    width: 100%;

    .sv-function-head {
      color: $sv-color-secondary40;
      border-top: 1px solid $sv-color-secondary20;
      border-bottom: 0;
    }
  }

  &.sv-section {
    background-color: $sv-color-neutral;
    max-width: 50vw;
    width: 100%;
  }
}



ul.sv-placeholder-list {
  li {
    cursor: pointer;
    color: $sv-color-primary80;

    &:hover {
      color: $sv-color-primary90;
      text-decoration: underline;
      background-color: $sv-color-info10;
    }
  }
}

.sv-placeholder {
  cursor: pointer;
  color: $sv-color-primary80;

  &:hover {
    color: $sv-color-primary90;
    text-decoration: underline;
    background-color: $sv-color-info10;
  }
}

.sv-placeholder-overflow {
  max-height: 400px;
  overflow: auto;
}

.popover-body {
  .sv-information-block {
    border: 0;
  }
}


.sv-information-block {
  position: relative;
  padding: 0.75rem 1.5rem 0.75rem 4rem;
  border: 1px solid $sv-color-dark;

  &:before {
    position: absolute;
    font-size: 2rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-left: -3.25rem;
  }

  &.sv-information-block-sm {

    padding: 0.75rem .75rem 0.75rem 3rem;
    font-size: .9rem;

    &:before {
      font-size: 1.25rem;
      margin-left: -2.1rem;
    }
  }

  .sv-alert-close {
    position: absolute;
    top: 0;
    right: 0.5rem;
    pointer-events: all;

    &:hover {
      cursor: pointer;
    }

    &:before {
      @include sv-fontawesome("\f00d");
      font-size: 1rem;
    }
  }
}

.sv-alert-container {
  position: fixed;
  top: 40px;
  width: 100%;
  pointer-events: none;
  z-index: 40;

  .sv-warning-block,
  .sv-info-block,
  .sv-success-block,
  .sv-danger-block {
    opacity: 0.9;
    margin-top: 0.25rem;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    pointer-events: all;
  }
}

.sv-warning-block {
  @extend.sv-information-block;
  background-color: $warning;
  color: $sv-color-dark;

  &:before {
    @include sv-fontawesome("\f06a");
  }
}

.sv-info-block {
  @extend.sv-information-block;
  background-color: $info;
  color: $sv-color-neutral;

  &:before {
    @include sv-fontawesome("\f05a");
  }
}

.sv-success-block {
  @extend.sv-information-block;
  background-color: $light;
  color: $sv-color-dark;

  &:before {
    color: $success;
    @include sv-fontawesome("\f14a");
  }
}

.sv-danger-block {
  @extend.sv-information-block;
  background-color: $danger;
  color: $sv-color-neutral;

  &:before {
    @include sv-fontawesome("\f071");
  }
}

.sv-section-closed {
  &:before {
    @include sv-fontawesome('\f054');
  }
}

.sv-section-open {
  &:before {
    @include sv-fontawesome('\f078');
  }
}

.sv-collapsable-head {
  &:before {
    font-size: 1rem;
    min-width: 25px;
    padding-right: .5rem;
  }

  &[aria-expanded="true"] {
    &:before {
      @include sv-fontawesome('\f078');
    }
  }

  &[aria-expanded="false"] {
    &:before {
      @include sv-fontawesome('\f054');
    }
  }
}