.sv-adv-section {
  @extend .mt-4;
  @extend .mb-5;
  @extend .px-5;
  @extend .py-4;
  @extend .container-fluid;
  background-color: $sv-color-background-qt;
}

.sv-adv-section-header {
  @extend .d-flex;
  max-width: 100%;

  .sv-adv-section-header-control {
    display: inline-block;
    @extend .pr-4;
    @extend .d-flex;
    @extend .align-items-center;

    &:before {
      color: $success;
    }

    &.sv-section-closed {
      &:before {
        @include sv-fontawesome('\f054');
      }
    }

    &.sv-section-open {
      &:before {
        @include sv-fontawesome('\f078');
      }
    }
  }

  .sv-adv-section-header-label {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .lead;
    @extend .text-truncate;
    width: 50%;

    span {
      @extend .text-truncate;
      min-width: 0;
    }
  }

  .sv-adv-section-header-selectall {
    background-color: $sv-color-neutral;
    border: 1px solid $sv-color-grey12;
    border-radius: 5px;
    padding: 1rem 2rem 1rem .5rem;
    margin-left: auto;
    white-space: nowrap;
  }

  .sv-adv-section-header-controls {
    background-color: $sv-color-neutral;
    border: 1px solid $sv-color-grey12;
    border-radius: 5px;
    padding: .5rem;
    margin-left: auto;
    white-space: nowrap;
  }

  .sv-adv-section-header-info {
    @extend .d-flex;
    @extend .align-items-center;
    background-color: $sv-color-neutral;
    border: 1px solid $sv-color-grey12;
    border-radius: 5px;
  }
}

.sv-adv-section-content {
  @extend .pt-3;
  max-width: 100%;
}

.sv-adv-section-element {
  @extend .row;
  @extend .p-3;
  @extend .my-3;
  @extend .mx-0;
  background-color: $sv-color-neutral !important;
  border: 1px solid $sv-color-grey12;
  border-radius: 5px;

  &.sv-adv-element-selected {
    background-color: $sv-color-grey12;
  }

  &.sv-filter {

    .sv-adv-section-element-content {
      @extend .m-0;
      @extend .col-12;
      @extend .p-3;

      .sv-adv-section-element-label {
        @extend .col-12;
      }
    }
  }
}


.sv-adv-section-element-item{
  display: flex;
  width: 100%;
    flex-wrap: wrap;
}

.sv-adv-section-element-type {
  @extend .pl-0;
  @extend .col-2;
  @extend .text-muted;
}

.sv-adv-section-element-content {
  @extend .pr-0;
  @extend .col-10;
  @extend .row;
}
.sv-adv-section-element-content  .sv-option-container{
  min-width: 11rem;
}
.sv-adv-section-element-label {
  @extend .p-0;
  @extend .col-11;
}
.sv-adv-section-element-qtext {
  @extend .p-0;
  @extend .col-8;
}
.sv-adv-section-element-code {
  @extend .p-0;
  @extend .col-3;
}

.sv-adv-section-element-control {
  @extend .d-flex;
  @extend .align-items-center;
  @extend .p-0;
  @extend .col-1;
  @extend .align-self-center;
  @extend .justify-content-end;
}


.custom-checkbox,
.custom-radio {
  &.custom-control-lg {
    label.custom-control-label {
      &:before {
        position: static;
        border-radius: .2rem !important;
        background-color: $sv-color-secondary20;
        border: 0;
        height: 1.5rem !important;
        width: 1.5rem !important;
        font-size: .9rem;
        line-height: 1.5rem;
        text-align: center;
        color: $success;
      }

      &:after {
        content: none;
      }

      &:hover {
        &:before {
          @include sv-fontawesome('\f00c');
        }

        &:after {
          content: none;
        }
      }
    }

    .custom-control-input:not(:disabled) {
      &+label.custom-control-label {
        &:hover:after {
          content: none;
        }
      }
    }

    .custom-control-input:not(:disabled):checked {

      &~label.custom-control-label,
      &~label.custom-control-label:hover {
        &:before {
          background-color: $success;
          color: $sv-color-neutral;
          @include sv-fontawesome('\f00c');

          z-index: 1;
        }

        &:after {
          content: none;
        }
      }

    }
  }
}

.sv-adv-choice {
  position: relative;
  margin-right: 1.5rem;
  margin-top: 1.5rem;
  width: 95px;
  @extend .text-center;

  &.sv-adv-choice-wide {
    width: 160px;
  }

  &.sv-adv-choice-long {
    .sv-adv-choice-img-container {
      padding-top: 3rem !important;
      padding-bottom: 2rem !important;
      margin-bottom: 1rem !important;
    }
  }

  &.sv-adv-message {
    width: 300px;
    position: relative;

    .sv-adv-message-preview {
      position: absolute;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.6);
      width: 100%;
      height: 500rem;
      max-height: 0;
      overflow: hidden;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      color: $sv-color-neutral;
      @extend .d-flex;
      @extend .justify-content-around;
      @extend .align-items-center;
      transition: max-height .5s ease;

      &:hover {
        cursor: zoom-in;
      }
    }

    .sv-adv-message-title {
      @extend .h6;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .sv-adv-message-layout {
      @extend .text-truncate;
    }

    .sv-adv-message-languages {
      @extend .mt-auto;
    }

    .sv-adv-message-img-container {
      width: 100%;
      height: 150px;
      overflow: hidden;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      background-color: $sv-color-neutral;
      margin-bottom: 1px;
    }

    .sv-adv-message-info-container {
      @extend .d-flex;
      @extend .flex-column;
      padding-top: 1rem;
      padding-bottom: 1rem;
      @extend .text-left;
    }

    .custom-checkbox,
    .custom-radio {
      &.custom-control-lg {

        label.custom-control-label {
          .sv-adv-message-label {
            overflow: hidden;
            background: $sv-color-neutral;
            border: 1px solid $sv-color-grey12;
            border-radius: 5px;
            padding: 0;
            min-height: 95px;
          }
        }
      }

      .custom-control-input:not(:disabled):checked {
        &+label.custom-control-label {
          .sv-adv-message-label {
            color: $primary;
            background: $sv-color-primary20;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);

          }

          &:before {
            content: none;
          }
        }

        &~.sv-adv-message-preview {
          max-height: 2rem;
        }
      }
    }
  }

  &.sv-adv-choice-color-template {

    .custom-checkbox,
    .custom-radio {
      &.custom-control-lg {

        label.custom-control-label {
          padding: 0;

          .sv-adv-choice-color-container {
            @extend .row;
            @extend .m-0;
            @extend .flex-nowrap;
            overflow: hidden;
            padding: 0 !important;
            max-height: 95px;

            &.sv-adv-default-color-set {
              border: .5rem solid $primary;
            }

            .sv-adv-choice-template-color {
              min-width: 2px;
              @extend .col;
              @extend .p-0;
              @extend .align-self-stretch;
              height: 500px;
            }
          }
        }
      }

      .custom-control-input:not(:disabled):not(:checked) {
        &+label.custom-control-label {
          .sv-adv-choice-template-color {
            opacity: 0.6;
          }

          &:hover {
            .sv-adv-choice-template-color {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .custom-checkbox,
  .custom-radio {
    &.custom-control-lg {
      padding-left: 0;

      label.custom-control-label {

        @extend .text-center;
        font-size: 0.875rem !important;
        margin-left: 0 !important;
        width: 100%;

        .sv-adv-choice-img-container {
          width: 100%;
          background: $sv-color-neutral;
          border: 1px solid $sv-color-grey12;
          border-radius: 5px;
          padding: 1rem;
          margin-bottom: .5rem;
          min-height: 95px;

          img {
            height: 60px;
          }

          .sv-adv-choice-img-text {
            margin-top: 1rem;
          }
        }

        &:before {
          position: absolute;
          top: -.75rem !important;
          right: -.75rem !important;
          left: auto !important;

        }
      }
    }

    .custom-control-input:not(:disabled):not(:checked) {
      &+label.custom-control-label {
        color: $sv-color-secondary40;

        img {
          opacity: 0.6;
        }

        &:before {
          background-color: transparent;
          content: none;
        }

        &:hover:before {
          content: none;
        }

        &:hover {
          color: $primary;

          img {
            opacity: 1;
          }
        }
      }
    }

    .custom-control-input:not(:disabled):checked {
      &+label.custom-control-label {
        color: $primary;

        .sv-adv-choice-img-container {
          background: $sv-color-primary20;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        }
      }
    }
  }
}

.sv-switch-btn-group {
  @extend .btn-group;

  .btn {
    border-radius: .25rem;
    box-shadow: none;
  }

  .btn-primary:not(:disabled):not(.disabled).active {
    background-color: $primary;
  }

  .btn-primary {
    background-color: $sv-color-neutral;
    color: $secondary;
    border: 1px solid $sv-color-grey12;
    padding: 5px !important;
    display: flex;
    align-items: center;
    margin-top: 2px;

    &.focus {
      box-shadow: none;
    }
  }
}