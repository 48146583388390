@import 'colors';
$border-radius: 0.15rem !default;
$border-radius-lg: 0.2rem !default;
$border-radius-sm: 0.1px !default;

$custom-control-indicator-border-width: 0.1562rem !default;
$custom-control-indicator-box-shadow: none !default;
$custom-control-indicator-border-color: $secondary !default;

$custom-control-indicator-checked-bg: $success !default;

$input-border-color: $sv-color-secondary20 !default;
$input-focus-border-color: $secondary !default;
$input-focus-box-shadow: none !default;

// Define Size of Custom Controls
.custom-control {
  .custom-control-label {
    &:hover {
      cursor: pointer;
    }

    &:before {
      top: 0.12rem;
      width: 1.25rem;
      height: 1.25rem;
    }

    &:after {
      top: 0.12rem;
      width: 1.25rem;
      height: 1.25rem;
      background: no-repeat 50% / 60% 60%;
    }
  }
  &.sv-icon-choice{    
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    display: inline-block;
    .custom-control-label {
      
      border-radius: 4px;
      border: 2px solid $sv-color-primary40;
      padding: 3px;
      margin-left: 0;
      margin-top: .75rem;
      background-color: #f9f9f9;
      min-width: 55px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-content: center;
      img {
        max-height: 100%;
      }
      &:hover {
        cursor: pointer;
      }
  
      &:before {
        top: -0.5rem;
        width: 1rem;
        height: 1rem;
        left: auto;
        right: -0.5rem;
        border-radius: 0;
        background-color: transparent;
        border-color: transparent;
        z-index: 9000;
      }
  
      &:after {
        top: -0.5rem;
        width: 1rem;
        height: 1rem;
        left: auto;
        right: -0.5rem;
        border-radius: 0;
        background: no-repeat 50% / 60% 60%;
        background-color: transparent;
        border-color: transparent;
        z-index: 9000;
      }
    }
    &.sv-icon-choice-text {
      .custom-control-label {
        padding-left: 10px; 
        padding-right: 10px;
        height: 33px;
      }
      &.sv-icon-button{
        .custom-control-label {
        box-shadow: 0.0625rem 0.0625rem 0.125rem 0 #8f8f8e;
        }
      }
    }
    
    &.sv-icon-choice-color .custom-control-label {
      display: block;
      height: 33px;
      padding: 0;
      .row {
        height: 28px;
        padding: 0 !important;
        width: 100%;
        margin: 0 !important;
        line-height: 1.85rem;
      }
      .col{
        padding: 0px;
        &:first-child{
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
        }
        &:last-child{
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
        }
      }
    }
    .custom-control-input {
      &:disabled {
        +.custom-control-label {
          &:before {
            background-color: transparent;
            border-color: transparent;
          }
          &:after {
            background-color: transparent;
            border-color: transparent;
          }
        }
      }
      &:focus:not(:checked) ~ .custom-control-label:before {
        border-color: transparent;
      }
    }
  }

  .custom-control-input {
    &:disabled {
      +.custom-control-label {
        &:hover {
          cursor: default;
        }
      }
    }
  }
}

// Custom Checkbox Design + Hover Effekt
.custom-checkbox {
  .custom-control-input {
    &:not(:disabled) {
      +.custom-control-label {
        &:after {
          font-size: 0.6rem;
          color: $success;
          padding-top: 0.15rem;
          text-align: center;
          padding-left: 0.05rem;
        }

        &:hover:after {
          @include sv-fontawesome(" \f00c");
        }
      }

      &:checked {
        +.custom-control-label {
          &:after {
            background: none;
            color: $sv-color-neutral;
            @include sv-fontawesome(" \f00c");
          }
        }

        &:active {
          +.custom-control-label {
            &:hover:after {
              @include sv-fontawesome(" \f00c");
            }
          }
        }
      }

      &:active {
        +.custom-control-label {
          &:before {
            border-color: $success;
            background: $success;
            transition-duration: 0ms;
          }

          &:after {
            background: none;
            color: $sv-color-neutral;
            @include sv-fontawesome(" \f00c");
          }
        }
      }
    }
  }

  &:disabled {
    +.custom-control-label {
      &:hover:after {
        content: '';
      }
    }
  }


  .custom-control-label {
    &:before {
      border-radius: 0 !important;
    }

  }
}


// Custom Radio Hover Effekt
.custom-radio {
  .custom-control-input {

    &:not(:disabled) {
      +.custom-control-label {
        &:hover:before {
          background: $success;
          box-shadow: inset 0 0 0 .1875rem $sv-color-neutral;
          transition-duration: 0ms;
        }
      }

      &:checked {
        +.custom-control-label {
          &:hover:before {
            box-shadow: none;
          }
        }
      }

      &:active {
        +.custom-control-label {
          &:before {
            border-color: $success;
            background: $success;
            box-shadow: inset 0 0 0 .1875rem $sv-color-neutral;
            transition-duration: 0ms;
          }

          &:after {
            background: none;
          }
        }
      }
    }

    &:disabled {
      +.custom-control-label {
        &:hover:before {
          box-shadow: none;
          background-color: $custom-control-indicator-disabled-bg;
        }
      }
    }
  }
  &.sv-icon-choice {
    .custom-control-input {
      &:not(:disabled) {
        +.custom-control-label {
          &:hover:before {
            content: '';
            background-color: transparent;
            border: none;
            box-shadow: none;
            transition: none;
          }
          &:hover:after{
            content: '';
            background-color: transparent;
            border: none;
            box-shadow: none;
            transition: none;
          }
        }
        &:not(:checked) {
          +.custom-control-label {
            box-shadow: 0.0625rem 0.0625rem 0.125rem 0 #8f8f8e;
            &:before{
              transition: none;
            }
            &:hover:before {
              background-color: transparent;
              border: none;
              box-shadow: none;
              transition: none;
            }
            &:hover:after{
              background-color: transparent;
              border: none;
              box-shadow: none;
              transition: none;
            }
          }
        }
        &:checked {
          +.custom-control-label {
            border-color: $success;
            background-color: $sv-color-primary10;
            box-shadow: none;
            &:before{
              
              background-color: $success;
              border-color: $success;
            }
            &:hover:before {
              box-shadow: none;
            }
            &:hover:after{
              @include sv-fontawesome(" \f00c");
              background: none;
              color: $sv-color-neutral;
              text-align: center;
              font-size: 11px;
            }
            &:after{
              background: none;
              color: $sv-color-neutral;
              @include sv-fontawesome(" \f00c");
              text-align: center;
              font-size: 11px;
            }
          }
        }

        &:active {
          +.custom-control-label {
            &:before {
              background-color: transparent;
              border: none;
              transition: none;
            }

            &:after {
              background-color: transparent;
              border: none;
            }
          }
        }
      }

      &:disabled {
        +.custom-control-label {
          opacity: 0.5;
          &:before {
            transition: none;
          }
          &:hover:before {
            background-color: transparent;
            border: none;
          }
          &:hover:after {
            background-color: transparent;
            border: none;
          }
        }
      }
    }
  }
}


// Large Control --> Currently Deprecated
.custom-control-lg {
  padding-left: 1.5rem;

  .custom-control-label::before,
  .custom-control-label::after {
    top: -0.05rem !important;
    left: -2rem !important;
    width: 1.43rem !important;
    height: 1.43rem !important;
  }

  .custom-control-label {
    margin-left: 0.5rem !important;
    font-size: 1rem !important;
  }

}

// Default Box Shadow of all Buttons
.btn {
  box-shadow: 0.0625rem 0.0625rem 0.125rem 0 $sv-color-secondary60;
  min-height: 44px;
}


// Sidebar Button Definitions
.btn.sv-btn-icon {
  line-height: 1.7rem;
  min-width: 44px;
  min-height: 44px;
}

.btn.sv-btn-active,
.btn.sv-btn-active:not(:disabled),
.btn.sv-btn-active:focus,
.btn.sv-btn-active:active {
  background-color: $sv-color-primary40 !important;
  box-shadow: inset 0.0625rem 0.0625rem 0.1rem 0 $sv-color-secondary60 !important;
}

.btn.sv-btn-sidebar {
  min-height: auto;
  line-height: 1rem;
  box-shadow: 0 0 1px 0 $sv-color-secondary60;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  border-radius: 0;

  &.sv-btn-sidebar-icon {
    min-width: 44px;
    padding: 0.375rem 0.75rem;
    line-height: 1.75rem;
  }

  &.btn-light {
    background-color: $light;
    border-color: $light;

    &:hover {
      background-color: $sv-color-primary10;
      border-color: $sv-color-primary10;
    }

    &:not(:disabled):not(.disabled) {
      &:focus {

        box-shadow: 0 0 .0625rem 0 $sv-color-secondary60;
      }

      &:active {
        box-shadow: none;

        &:focus {

          box-shadow: 0 0 .0625rem 0 $sv-color-secondary60;

        }
      }
    }
  }
}

.bg-light {
  .btn.sv-btn-sidebar {
    &.btn-light {
      border: 1px solid $sv-color-primary10;
    }
  }
}

.btn.sv-btn-slim {
  @extend .sv-btn-sidebar;
  min-height: auto;
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn.btn-light {
  background-color: $sv-color-primary10;
  border-color: $sv-color-primary10;

  &:hover {
    background-color: $sv-color-primary20;
    border-color: $sv-color-primary20;
  }

  &.active {
    background-color: $sv-color-primary20;
    border-color: $sv-color-primary20;
  }
}

.modal-footer {
  .btn {
    &.btn-light {
      &.sv-btn-slim {
        background-color: $sv-color-primary10;
        border-color: $sv-color-primary10;

        &:hover {
          background-color: $sv-color-primary20;
          border-color: $sv-color-primary20;
        }
      }
    }
  }
}

// White Button
.btn.btn-white {
  background-color: $sv-color-neutral;
  border: .0625rem solid $sv-color-dark;
}

// Paddings + Active & Focus State of Buttons 
.btn.btn-primary,
.btn.btn-light,
.btn.btn-secondary,
.btn.btn-success,
.btn.btn-info,
.btn.btn-warning,
.btn.btn-danger,
.btn.btn-white,
.btn.btn-dark {
  &:not(.sv-btn-sidebar):not(.sv-btn-icon):not(.sv-btn-slim):not(.nav-item) {
    padding: 0.575rem 0.75rem;
  }

  &:not(:disabled):not(.disabled):not(.sv-btn-sidebar) {
    &:focus {

      box-shadow: 0.0625rem 0.0625rem 0.125rem 0 $sv-color-secondary60;
    }

    &:active {
      box-shadow: none;

      &:focus {

        box-shadow: 0.0625rem 0.0625rem 0.125rem 0 $sv-color-secondary60;

      }
    }
  }
}

.badge {
  font-weight: unset !important;
}

.btn-success,
.badge-success {
  color: $sv-color-neutral !important;
}

.sv-btn {
  @extend .btn;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
}

.sv-btn-common {
  @extend .btn-info;
}

.sv-btn-primary,
a.sv-btn-primary:not([href]):not([tabindex]) {
  @extend .btn-primary;
}

.sv-btn-inverse,
.sv-btn-inverse:hover {
  @extend .sv-btn;
  background-color: white;
  border-block: 0.0625rem solid black;
  box-shadow: 0.0625rem 0.0625rem 0.125rem 0 #8F8F8E;
  color: $secondary;
}

.sv-btn-action {
  @extend .sv-btn,
  .btn-success;
}


.sv-btn-menu-dropdown {
  &:hover {
    &+.sv-elements-menu {
      display: block;
    }

    &+.dropdown-menu {
      display: block;
    }
  }

  &+.dropdown-menu {
    margin: 0;

    &:hover {
      display: block;
    }
  }
}


.sv-elements-menu {
  position: absolute;
  z-index: 1;
  width: 50vw;
  max-height: calc(100vh - 7.4rem);
  overflow: auto;

  &:hover {
    display: block;
  }

  .sv-elements-items {
    .btn {
      min-width: 12rem;
      max-width: 12rem;
      font-size: 0.9rem;
      cursor: move;
      overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .sv-elements-menu {
    width: 41.66vw;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .sv-elements-menu {
    width: 33.33vw;
  }
}

.sv-da-page-builder .sv-elements-menu {
  max-width: 500px;
}


.sv-error-msg {
  color: $sv-color-neutral;
  background-color: $warning;
  padding: 0.9375rem 0.9375rem 0.9375rem 2.8125rem !important;

  :after {
    @include sv-fontawesome("\f05a");
    left: .9375rem;
    position: absolute;
    top: 50%;
    -moz-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    font-size: 1.5rem;
  }
}

// Search Text Input
.form-control {
  font-family: $font-family-sans-serif;

  &.sv-input-search {
    width: 15rem;
    border-radius: 0;
    padding: 0 0.55rem;
    height: calc(1.5em + 1rem + 4px);
    font-size: 1rem;
    color: $sv-color-dark;
  }

  &.form-control-sm {
    color: $sv-color-dark;
    font-size: 1rem;
    height: calc(1.5em + 0.5rem + 4px);
  }
}

.sv-rte-opener {
  position: absolute;
  top: -1.05rem;
  right: 0.25rem;
  display: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.sv-textarea-container {
  width: 100%;
  height: 8rem;
  position: relative;
}

.sv-textinput-container {
  width: 100%;
  position: relative;
  color: $sv-color-dark;
  font-size: 1rem;

  .sv-textarea {
    max-height: 4rem;
  }
}


.sv-textarea {
  height: 100%;
  padding: 0.25rem 0.5rem;
  min-height: calc(1.5em + 0.5rem + 2px);
  width: 100%;
  overflow: auto;
  background-color: white;
  border: 1px solid $sv-color-secondary20;
  color: $sv-color-dark;
  font-size: 1rem;

  &.fraola-focus+.sv-rte-opener {
    display: inline;
  }

  &.fraola-focus {
    border-color: $sv-color-dark;
  }

  p {
    margin: 0;
  }
}

textarea {
  &.form-control {
    &.sv-textarea-no-rte {
      @extend.sv-textarea;
      height: 8rem;
      resize: none;

      &:focus {
        border-color: $sv-color-dark;
      }
    }
  }
}

.sv-error-border {
  border: 1px solid $danger !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.warning {
  color: $danger;
  width: 100%;
  padding: 0.625rem;
  font-size: 0.8rem;
}