@import 'colors';

editor {
    background: $sv-color-neutral;
    width: 1024px;
}

.inline-editor {
    background: $sv-color-neutral;
    width: 100%;
}

// FontAwesome 5 Fix for Froala
.fa-rotate-left:before {
    //@extend.fa-undo;
    content: '\f0e2';
}

.fa-rotate-right:before {
    //@extend.fa-redo;
    content: '\f01e';
}

.fa-video-camera:before {
    //@extend.fa-video;
    content: '\f03d';
}

.fa-file-o:before {
    //@extend.fa-file-upload;
    content: '\f574';
}

.fa-smile-o:before {
    //@extend.fa-smile-wink;
    content: '\f4da';
}

.fa-file-pdf-o:before {
    //@extend.fa-file-pdf;
    content: '\f1c1';
}

.fa-arrows-v:before {
    //@extend.fa-arrows-alt-v;
    content: '\f338';
}

.fa-external-link:before {
    //@extend.fa-external-link-alt;
    content: '\f35d';
}