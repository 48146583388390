$sv-login-background: $sv-color-primary10;

$sv-default-layout-margin-x: 6.25rem !default;
$sv-default-layout-margin-top: 2rem !default;

$sv-header-margin-top: 1.875rem !default;
$sv-header-margin-bottom: $sv-header-margin-top !default;
$sv-header-height: 2.75rem !default;

html,
body {
  height: 100%;
  text-rendering: optimizeLegibility;
}

.sv-main-layout-row {
  margin-left: $sv-default-layout-margin-x;
  margin-right: $sv-default-layout-margin-x;
  margin-top: $sv-default-layout-margin-top;

  >header {
    margin-top: $sv-header-margin-top;
    margin-bottom: $sv-header-margin-bottom;
    height: $sv-header-height;

    display: flex !important;
    flex-flow: row nowrap;
    justify-content: flex-start;

    .sv-flex-auto {
      flex-grow: 1;
    }

    .sv-flex-end {
      margin-left: auto;
      align-self: flex-end;
    }
  }
}

.sv-survey-layout-row {
  margin-top: $sv-default-layout-margin-top;
  min-width: 1000px;

  .sv-padding-left {
    padding-left: $sv-default-layout-margin-x;

    @media(max-width: 1200px) {
      & {
        padding-left: calc(#{$sv-default-layout-margin-x} * 0.5);
      }
    }
  }

  .sv-padding-right {
    padding-right: $sv-default-layout-margin-x;

    @media(max-width: 1200px) {
      & {
        padding-left: calc(#{$sv-default-layout-margin-x} * 0.5);
      }
    }
  }
}



.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
}

.root-layout-container {
  display: grid;
  grid-template-rows: 40px 100px auto;
  grid-template-columns: 100%;
  grid-template-areas:
    "navbar"
    "root-pane-header"
    "root-pane";
  margin: 0;
  padding: 0;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;

  overflow: hidden; // no scroll bars on the outer container
}

.root-navbar {
  grid-area: navbar;
}

.root-pane-header {
  grid-area: root-pane-header;
  margin-left: 100px;
  margin-right: 100px;
}

.root-pane {
  grid-area: root-pane;
  margin-left: 100px;
  margin-right: 100px;
  overflow-y: auto; // show scrollbar if required
  scroll-behavior: smooth;
}

$login-logo-width: 300px;

.sv-login-layout {
  width: 100%;
  height: 100vh;
  background-color: $sv-login-background;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  form,
  .sv-error-msg,
  header {
    width: $login-logo-width;
  }

  header {
    margin-top: 5rem;
  }

  form {
    margin-top: 3rem;
  }

  footer {
    margin-top: auto;
    margin-bottom: 1rem;
  }
}

.sv-page:hover {
  background-color: $sv-color-primary10;
  cursor: pointer;
}

.sv-page-active {

  background-color: $sv-color-primary5;
}

.sv-sidebar-nav {
  position: absolute;
  display: flex;
  z-index: 2;
  right: 0;

  input.sv-sidebar-collaps-checkbox {
    display: none;
  }

  .sv-sidebar-content {
    background-color: $sv-color-primary20;
    width: 0px;
    height: calc(100vh - 9.4rem);
    box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
    transition: .7s width;
    overflow-x: hidden;

    .sv-sidebar-row {
      width: 498px;
    }


  }



  .sv-sidebar-collaps-checkbox:checked~.sv-sidebar-content,
  &.open .sv-sidebar-content {
    width: 500px;
    transition: .7s width;
  }

  .sv-sidebar-close {
    height: 100%;
    position: relative;
  }

  .sv-nav-close {
    transform: rotate(-90deg);
    position: absolute;
    top: 75px;
    left: -90px;
    background-color: $sv-color-primary40;
    color: $secondary;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    padding: 3px 10px 1px;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }

  .sv-nav-close-label {
    min-width: 120px;
    text-align: center;
  }

  .sv-nav-close-arrow:before {
    @include sv-fontawesome('\f0d7');
  }
}

@media (min-width: 1600px) {
  .sv-sidebar-nav {
    position: static;
  }

  .sv-sidebar-content {
    width: 500px !important;
    box-shadow: none !important;
  }

  .sv-sidebar-close {
    display: none !important;
    box-shadow: none !important;
  }

  .sv-da-page-content {
    max-width: calc(100vw - 500px) !important;
  }
}