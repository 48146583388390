// Put your variables before the GrapesJS style import
@import 'colors';
// Palette variables
$primaryColor: $sv-color-primary20;
$secondaryColor: $secondary;
$tertiaryColor: $success;
$quaternaryColor: $dark;
/* Dark theme */
$mainColor: $sv-color-primary20 !default;
/* Light: #573454 Dark: #3b2639 -moz-linear-gradient(top, #fca99b 0%, #6e2842 100%) */
$fontColor: $sv-color-dark !default;
/* l: #d8d7db */
$fontColorActive: $success !default;

$mainDkColor: $sv-color-primary20;
/* darken($mainColor, 4%) - #383838 */
$mainDklColor: $sv-color-primary20 !default;
$mainLhColor: $dark !default;
/* #515151 */
$mainLhlColor: $light !default;
$fontColorDk: $dark !default;
$colorBlue: $primary !default;
$colorRed: $danger !default;
$colorYell: $dark !default;
$colorGreen: $primary !default;
$tagBg: $sv-color-primary20 !default;
$secColor: $tagBg !default;
$imageCompDim: 50px !default;
$leftWidth: 15% !default;

/* Color Helpers */
$colorHighlight: $success !default;
$colorWarn: $dark !default;

/* Canvas */
$hndlMargin: -5px !default;

/* Components / Inputs */
$lightBorder: rgba(255, 255, 255, 0.05) !default;
$inputFontColor: $mainLhlColor !default;
/* #d5d5d5 */
$arrowColor: $mainLhlColor !default;
/* b1b1b1 */
$darkTextShadow: $mainDkColor !default;
/* #252525 */
$darkBorder: rgba(0, 0, 0, 0.15) !default;
/* 303030 */
$colorpSize: 22px !default;
$inputPadding: 5px !default; // Has to be a single value

/* Class manager */
$addBtnBg: lighten($mainDkColor, 10%) !default;
$paddElClm: 5px 6px !default;

/* File uploader */
$uploadPadding: 150px 10px !default;

/* Commands */
$animSpeed: 0.2s !default;

/* Fonts */
$fontSize: 0.7rem !default;
$fontSizeS: 0.75rem !default;
$fontV: 20 !default; //random(1000)
$fontPath: '/assets/default/js/grapesjs/fonts' !default;


@import "~grapesjs/src/styles/scss/main.scss";


.gjs-cv-canvas {
  width: 100%;
  height: 100%;
  top: 0;
  background-color: transparent;
}

.gjs-one-bg {
  background-color: transparent;
}

.gjs-pn-panels {
  display: none;
}

.gjs-pn-views {
  border-bottom: 0;
  box-shadow: 0 0 5px $sv-color-primary20;
}

.gjs-editor-cont {
  height: calc(100% - 56px);
}

.gjs-pn-panels {
  @extend .text-center;
}

.gjs-clm-tags-btn svg {
  height: 100%;
}

.gjs-clm-tag-status svg,
.gjs-clm-tag-close svg {
  height: 18px;
}

.gjs-toolbar-item.fa.fa-arrows:before {
  @include sv-fontawesome('\f0b2');
}

.gjs-toolbar-item.fa.fa-trash-o:before {
  @include sv-fontawesome('\f2ed');
}

.gjs-rte-toolbar {
  display: none;
}

.gjs-block-category .gjs-title {
  background-color: transparent;
  color: $sv-color-neutral;
}

.gjs-category-open,
.gjs-sm-sector.gjs-sm-open,
.gjs-sm-open.gjs-clm-tags,
.gjs-block-category.gjs-open {
  border: 0;
}

.gjs-category-title,
.gjs-sm-sector .gjs-sm-title,
.gjs-clm-tags .gjs-sm-title,
.gjs-block-category .gjs-title,
.gjs-layer-title {
  border: 0;
}

// Custom scrollbars for Chrome
.sv-content-container {
  .gjs-editor-cont::-webkit-scrollbar-track {
    background-color: #000;
  }

  .gjs-editor-cont::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
    background-color: #fff;
  }

  .gjs-editor-cont::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
  }
}