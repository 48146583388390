.sv-special-loading-container {
  max-width: 900px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.sv-get-coffee-container {
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary;

  .sv-coffee-icon:before {
    @include sv-fontawesome("\f7b6");
    font-size: 8rem;
  }

  .sv-coffee-message {
    padding-left: 1.5rem;
    font-size: 1.2rem;
    text-align: center;
  }
}

.sv-thumbs-up-container {
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;

  .sv-thumbs-up-icon:before {
    @include sv-fontawesome("\f164");
    font-size: 8rem;
    color: $success;
  }

  .sv-thumbs-up-message {
    color: $primary;
    padding-left: 1.5rem;
    font-size: 1.2rem;
    text-align: center;
  }
}

.sv-warning-container {
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;

  .sv-warning-icon:before {
    @include sv-fontawesome("\f071");
    font-size: 8rem;
    color: $warning;
  }

  .sv-warning-message {
    color: $primary;
    padding-left: 1.5rem;
    font-size: 1.2rem;
    text-align: center;
  }
}

.sv-loading-card-row {
  display: flex;

  justify-content: space-between;

  .sv-loading-card-connector {
    height: 75px;
    min-width: 5%;
  }



}

.sv-loading-card-connector-row {
  height: 40px;
  width: 100%;
}

.sv-loading-card {
  background-color: $sv-color-primary5;
  border-radius: 5px;
  border: 1px solid $sv-color-secondary20;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  padding: 1rem;
  max-width: 400px;
  min-height: 150px;
  width: 100%;

  .sv-loading-step {
    min-height: 45px;
    min-width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    border-radius: 50%;
    font-size: 1.2rem;
    line-height: 1.2rem;
    color: $sv-color-neutral;
    background-color: $sv-color-primary80;
  }

  .sv-loading-content {
    font-size: 1rem;
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;

    .sv-loading-heading {
      font-weight: bold;
      margin-bottom: 0.8rem;
    }
  }

  .sv-loading-status {
    min-height: 60px;
    min-width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    .sv-loading-done-box {
      height: 30px;
      width: 30px;
      border-radius: 3px;
      background-color: $success;
      display: flex;
      justify-content: center;
      align-items: center;

      &:before {
        @include sv-fontawesome(" \f00c");
        color: $sv-color-neutral;
      }
    }

    .rt-spin-container {
      margin-top: 10px;
      max-width: 60px;
      max-height: 50px;

      .rt-spin {
        border: 2px solid $sv-color-success60;
        width: 35px;
        height: 35px;
        margin: 0 auto;
        border-radius: 50%;
        border-left-color: transparent;
        border-right-color: transparent;
        animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
      }

      .rt-spin-loader-2 {
        border: 2px solid $success;
        width: 43px;
        height: 43px;
        position: relative;
        top: -39px;
        border-left-color: transparent;
        border-right-color: transparent;
        animation: rotate2 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
      }

      .rt-spin-loader-3 {
        display: none;
      }

      .rt-spin-loader-4 {
        display: none;
      }

      .rt-spin-text {
        font-size: 12px;
        top: -70px;
        color: $primary;
        position: relative;
      }

      &.no-spin {
        .rt-spin {
          animation: none;
        }

        .rt-spin-loader-2 {
          animation: none;
        }

        .rt-spin-loader-3 {
          display: none;
        }

        .rt-spin-loader-4 {
          display: none;
        }
      }
    }
  }

}