
.sv-distribute-new-sample-container {
  @extend .d-flex;
  @extend .flex-wrap;
  @extend .justify-content-between;
  @extend .pt-2;
}

.sv-add-sample-logo {
  height: 100%;
  width: 100%;
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .align-content-center;

  img {
    max-height: 40px;
  }
}

.sv-distibute-ds-head-container {
  @extend .container-fluid;
}

.sv-distibute-ds-head-text {
  @extend .align-self-center;
  @extend .p-0;
}

.sv-distibute-ds-head-search {
  @extend .pr-0;

  div {
    @extend .d-flex;
    @extend .flex-fill;
    @extend .mr-3;

    input.form-control {
      @extend .d-inline-block;
      @extend .w-100;
    }
  }
}

.sv-distibute-ds-head-seperator {
  @extend .row;

  div {
    @extend .col-12;

    hr {
      @extend .mr-3;
    }
  }
}

.sv-content-container {
  .sv-distribute-new-sample-header {
    @extend .lead;
  }

  .sv-distibute-ds-head-container {
    @extend .pt-5;
  }

  .sv-distibute-ds-head-text {
    @extend .col-6;

    span {
      @extend .lead;
    }
  }

  .sv-distibute-ds-head-search {
    @extend .col-6;

    div {
      input.form-control {
        @extend .sv-input-search;
      }
    }
  }
}

.sv-elements-menu {
  .sv-distibute-ds-head-container {
    @extend .pt-3;
  }

  .sv-distibute-ds-head-text {
    @extend .col-4;
  }

  .sv-distibute-ds-head-search {
    @extend .col-8;

    button {
      min-height: 38px;
      max-height: 38px;
      min-width: 38px;
    }
  }
}

.sv-sample-tile {
  border-radius: .25rem;
  background-color: $sv-color-primary10;
  color: $secondary;
  width: 265px;
  padding: 0;
  padding-top: 1.5rem;
  box-shadow: 0.0625rem 0.0625rem 0.125rem 0 $sv-color-secondary60;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.sv-sample-tile-title {
  @extend .px-3;
  @extend .text-truncate;
}

.btn.sv-btn-sample-view {
  @extend .btn-dark;
  @extend .sv-btn-slim;
  @extend .w-100;
  border-bottom-left-radius: .25rem;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.btn.sv-btn-sample-use {
  @extend .btn-success;
  @extend .sv-btn-slim;
  @extend .w-100;
  border-bottom-right-radius: .25rem;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.btn.sv-btn-panel-request {
  @extend .btn-primary;
  @extend .sv-btn-slim;
  @extend .w-100;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
  padding-top: .75rem;
  padding-bottom: .75rem;
}


.sv-elements-menu {
  .btn.sv-btn-sample-use {
    @extend .btn-primary;
  }

  .btn.sv-btn-panel-request {

    @extend .btn-secondary;
  }

  .btn.sv-btn-sample-view,
  .btn.sv-btn-sample-use {
    .sv-sample-tile-btn-label {
      display: none;
    }
  }

  .sv-sample-tile {
    width: 200px;
    margin-bottom: .5rem;
    margin-right: .5rem;
  }
}

.sv-sample-projects-container {
  @extend .container-fluid;
}

.sv-sample-project {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: .75rem;
  border-radius: 4px;
  color: $sv-color-secondary80;
  border: 1px solid $sv-color-primary10;
  background-color: $sv-color-primary10;
  @extend .row;

  &:not(.sv-sample-project-selected):hover {
    background-color: $sv-color-primary20;
    cursor: pointer;
  }

  &.sv-sample-project-draft {
    border: 1px solid $primary;
    background-color: $sv-color-neutral;
  }

  &.sv-sample-project-selected {
    border: 1px solid $sv-color-primary40;
    background-color: $sv-color-primary40;

    .sv-sample-project-name,
    .sv-sample-project-type,
    .sv-sample-project-statistic,
    .sv-sample-project-status {
      border-right: 1px solid $sv-color-secondary80;
    }
  }

}

.sv-sample-project-name,
.sv-sample-project-type,
.sv-sample-project-statistic,
.sv-sample-project-status {
  border-right: 1px solid $sv-color-secondary40;
}

.sv-sample-project-name {
  @extend .col-4;
  @extend .d-flex;
  @extend .align-items-center;
  @extend .py-1;
}

.sv-sample-project-type {
  @extend .col-2;
  @extend .text-center;
  @extend .d-flex;
  @extend .flex-column;
  @extend .align-items-center;
  @extend .justify-content-center;
  @extend .py-1;

  .sv-sample-project-icon {
    font-size: 2rem;
  }

  .sv-sample-project-label {
    font-size: .75rem;
  }
}

.sv-sample-project-statistic {
  @extend .col-2;
  @extend .text-center;
  @extend .d-flex;
  @extend .flex-column;
  @extend .align-content-center;
  @extend .justify-content-center;
  @extend .py-1;

  .sv-sample-project-statistic-top {
    font-size: 2rem;
  }

  .sv-sample-project-statistic-bottom {
    font-size: .85rem;
  }
}

.sv-sample-project-status {
  @extend .col-1;
  @extend .text-center;
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
  @extend .py-1;
  font-size: .75rem;
}

.sv-sample-project-action {
  @extend .col-3;
  @extend .text-center;
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
  @extend .py-1;
}