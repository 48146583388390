.sv-progress-overlay {
    position:absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255,255,255,0.7);
    z-index: 1000000;

    &.global {
        position: fixed;
    }
}
.floatingCirclesG {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 51px;
    height: 51px;
    margin: auto;
    transform: scale(0.6);
    -o-transform: scale(0.6);
    -ms-transform: scale(0.6);
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
}

.f_circleG {
    position: absolute;
    background-color: rgb(255, 255, 255);
    height: 9px;
    width: 9px;
    border-radius: 5px;
    -o-border-radius: 5px;
    -ms-border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    animation-name: f_fadeG;
    -o-animation-name: f_fadeG;
    -ms-animation-name: f_fadeG;
    -webkit-animation-name: f_fadeG;
    -moz-animation-name: f_fadeG;
    animation-duration: 0.622s;
    -o-animation-duration: 0.622s;
    -ms-animation-duration: 0.622s;
    -webkit-animation-duration: 0.622s;
    -moz-animation-duration: 0.622s;
    animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-direction: normal;
    -o-animation-direction: normal;
    -ms-animation-direction: normal;
    -webkit-animation-direction: normal;
    -moz-animation-direction: normal;
}

.frotateG_01 {
    left: 0;
    top: 21px;
    animation-delay: 0.2295s;
    -o-animation-delay: 0.2295s;
    -ms-animation-delay: 0.2295s;
    -webkit-animation-delay: 0.2295s;
    -moz-animation-delay: 0.2295s;
}

.frotateG_02 {
    left: 6px;
    top: 6px;
    animation-delay: 0.316s;
    -o-animation-delay: 0.316s;
    -ms-animation-delay: 0.316s;
    -webkit-animation-delay: 0.316s;
    -moz-animation-delay: 0.316s;
}

.frotateG_03 {
    left: 21px;
    top: 0;
    animation-delay: 0.3925s;
    -o-animation-delay: 0.3925s;
    -ms-animation-delay: 0.3925s;
    -webkit-animation-delay: 0.3925s;
    -moz-animation-delay: 0.3925s;
}

.frotateG_04 {
    right: 6px;
    top: 6px;
    animation-delay: 0.469s;
    -o-animation-delay: 0.469s;
    -ms-animation-delay: 0.469s;
    -webkit-animation-delay: 0.469s;
    -moz-animation-delay: 0.469s;
}

.frotateG_05 {
    right: 0;
    top: 21px;
    animation-delay: 0.5455s;
    -o-animation-delay: 0.5455s;
    -ms-animation-delay: 0.5455s;
    -webkit-animation-delay: 0.5455s;
    -moz-animation-delay: 0.5455s;
}

.frotateG_06 {
    right: 6px;
    bottom: 6px;
    animation-delay: 0.622s;
    -o-animation-delay: 0.622s;
    -ms-animation-delay: 0.622s;
    -webkit-animation-delay: 0.622s;
    -moz-animation-delay: 0.622s;
}

.frotateG_07 {
    left: 21px;
    bottom: 0;
    animation-delay: 0.6985s;
    -o-animation-delay: 0.6985s;
    -ms-animation-delay: 0.6985s;
    -webkit-animation-delay: 0.6985s;
    -moz-animation-delay: 0.6985s;
}

.frotateG_08 {
    left: 6px;
    bottom: 6px;
    animation-delay: 0.775s;
    -o-animation-delay: 0.775s;
    -ms-animation-delay: 0.775s;
    -webkit-animation-delay: 0.775s;
    -moz-animation-delay: 0.775s;
}

@-o-keyframes f_fadeG {
    0% {
        background-color: rgb(0, 0, 0);
    }

    100% {
        background-color: rgb(255, 255, 255);
    }
}

@-ms-keyframes f_fadeG {
    0% {
        background-color: rgb(0, 0, 0);
    }

    100% {
        background-color: rgb(255, 255, 255);
    }
}

@-webkit-keyframes f_fadeG {
    0% {
        background-color: rgb(0, 0, 0);
    }

    100% {
        background-color: rgb(255, 255, 255);
    }
}

@-moz-keyframes f_fadeG {
    0% {
        background-color: rgb(0, 0, 0);
    }

    100% {
        background-color: rgb(255, 255, 255);
    }
}