@import "colors";

$navbar-padding-y: 0 !default;
$navbar-padding-x: 1rem !default;
$navbar-nav-link-padding-x: 1.25rem !default;
$sv-nav-link-active-background-color: $sv-color-primary90 !default;

.navbar.bg-primary {
    background-color: $primary !important;

    .nav-link {
        color: $sv-color-neutral !important;
    }

    .nav-item.nav-link:hover:not(.active) {
        color: $sv-color-secondary10 !important;
    }

    .navbar-brand {
        color: $sv-color-neutral !important;
        background-color: $primary !important;
    }

    .nav-link.active {
        background-color: $sv-nav-link-active-background-color !important;
    }
}

.navbar.bg-secondary {
    background-color: $sv-color-sub-nav-bg !important;

    .nav-link {
        color: $secondary !important;
    }

    .nav-item.nav-link:hover:not(.active) {
        color: $secondary !important;
        background-color: $sv-color-primary10 !important;
    }

    .navbar-brand {
        color: $sv-color-neutral !important;
        background-color: $primary !important;
        font-size: 1rem;
        line-height: 1.875rem;
    }

    .sv-nav-heading {
        color: $sv-color-dark;
    }

    .nav-link.active {
        background-color: $sv-color-primary20 !important;
    }

    .btn {
        &:hover {
            background-color: $primary;
            color: $sv-color-main-nav-bg-hover;
            border-color: $primary;
        }
    }

    .btn-primary.dropdown-toggle:not(.sv-btn-sidebar):not(.sv-btn-icon):not(.sv-btn-slim) {
        background-color: $primary;
        color: $sv-color-main-nav-bg-hover;
        border-color: $primary;
        padding-top: 0.125rem;
        padding-bottom: 0.125rem;

        &:after {
            margin-left: 0;
        }
    }

    .show {
        .btn-primary.dropdown-toggle {
            background-color: $primary;
            color: $sv-color-main-nav-bg-hover;
            border-color: $primary;
        }
    }

    .dropdown-menu {
        left: initial;
        right: 0;
    }
}

.navbar {
    .btn {
        padding-top: 0.125rem;
        padding-bottom: 0.125rem;
        height: 1.75rem;
        margin-top: auto;
        min-height: auto;
        margin-bottom: auto;
        border-radius: 0;
        box-shadow: .0625rem .0625rem .125rem 0 $sv-color-secondary60;

        &:focus {
            box-shadow: none !important;
        }
    }

    .dropdown {
        &:hover {
            &>.dropdown-menu {
                display: block;
                margin: 0;
            }
        }
    }

}


$dropdown-border-width: 0 !default;
$dropdown-border-color: $sv-color-sub-nav-bg !default;
$dropdown-bg: $sv-color-sub-nav-bg !default;

$dropdown-link-hover-bg: $sv-color-sub-nav-bg-active !default;
$dropdown-link-active-bg: $sv-color-sub-nav-bg-active !default;
$dropdown-link-active-color: $secondary !default;

$dropdown-padding-y: 0 !default;
$dropdown-item-padding-x: 1.25rem !default;
$dropdown-item-padding-y: 0 !default;

$sv-dropdown-item-height: 3.125rem !default;
$sv-dropdown-item-line-height: $sv-dropdown-item-height !default;
$sv-dropdown-item-min-width: 9.375rem !default;


.dropdown-item {
    height: $sv-dropdown-item-height;
    line-height: $sv-dropdown-item-line-height;
    min-width: $sv-dropdown-item-min-width;
}