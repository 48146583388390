.sv-tpl-device-selctor {
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .mb-3;
  background-color: $sv-color-primary20;
  position: relative;
  margin-right: 20px;
  margin-left: 20px;
  height: 40px;
  transition: width .33s;
  transition-timing-function: ease;

  &:after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-left-color: $sv-color-primary20;
    border-width: 20px;
    margin-top: -20px;
  }

  &:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-right-color: $sv-color-primary20;
    border-width: 20px;
    margin-top: -20px;
  }
}

.sv-tpl-device {
  width: 40px;
  text-align: center;
  @extend .align-self-center;
  color: $sv-color-primary40;

  &.sv-icon-active {
    color: $primary;
  }
}

.sv-tpl-control-bar {
  @extend .d-flex;
  @extend .justify-content-center;
  max-width: 100%;
  overflow-x: hidden;
}

.sv-tpl-device-width-desktop {
  width: 100%;
}

.sv-tpl-device-width-tablet {
  width: calc(768px - 40px);
}

.sv-tpl-device-width-mobile {
  width: calc(320px - 40px);
}

.sv-elements-menu {
  .sv-tpl-element {
    background-color: $light;
    flex-direction: row;
    display: flex;
    justify-content: start;
    align-items: center;
    min-height: 50px;
    @extend .py-1;

    &:hover {
      background-color: $sv-color-secondary10;
    }

    &.fa,
    &.fas {
      line-height: 1.5rem;
      font-size: 1.5rem;
    }

    &.gjs-fonts:before {
      font-size: 2rem;
    }

    &:before {
      width: 50px;
    }

    .gjs-block-label {
      font-size: 1rem;
    }
  }
}

.sv-sidebar {
  .sv-tpl-sidebar-section {
    max-width: 450px;
  }

  .gjs-sm-title {
    @extend .sv-setting-header;
    font-weight: bold !important;
    padding-left: 0px !important;

    .fa-caret-right:before {
      @include sv-fontawesome('\f054');
      font-size: 16px;
    }

    .fa-caret-down:before {
      @include sv-fontawesome('\f078');
      font-size: 16px;
    }
  }

  .gjs-sm-sector .gjs-sm-property .gjs-sm-btn-c .gjs-sm-btn,
  .gjs-clm-tags .gjs-sm-property .gjs-sm-btn-c .gjs-sm-btn {
    @extend .btn;
    @extend .sv-btn-sidebar;
    @extend .btn-light;
    text-shadow: none;
    @extend .rounded-0;
    font-size: 14px !important;
  }

  .gjs-field {
    background-color: $sv-color-neutral;

    &.gjs-sm-layers {
      height: auto !important;
      margin: 0;
      @extend .p-0;

      .gjs-sm-layer.gjs-sm-active {
        @extend .bg-primary20;
        margin: 0;
        border-radius: 0;
        border: 1px solid $sv-color-primary10;

        .gjs-sm-properties {
          @extend .bg-primary10;
          @extend .mt-1;
        }
      }
    }

    .fa {
      @extend .fas;

      &.fa-arrows:before {
        @include sv-fontawesome('\f0b2');
      }
    }

    &.gjs-field-radio {
      background-color: transparent;
    }

    &:not(.gjs-field-radio):not(.gjs-sm-layers) {
      @extend .form-control;
      @extend .form-control-sm;
      padding: 0 !important;

      input {
        line-height: 1.3rem;
      }
    }
  }


  .gjs-field-arrow-u {
    border-bottom-color: $dark;
  }

  .gjs-field-arrow-d {
    border-top-color: $dark;
  }

  .gjs-sm-sector .gjs-sm-field input,
  .gjs-sm-sector .gjs-sm-field select {
    color: $dark;
  }

  .gjs-field .gjs-d-s-arrow {
    border-top-color: $dark;
  }

  .gjs-radio-item {
    @extend .d-flex;
    @extend .justify-content-center;
    flex: none;
    @extend .mr-2;

    &:hover {
      background-color: transparent;
    }
  }

  .gjs-radio-item-label {
    @extend .btn;
    @extend .btn-light;
    @extend .sv-btn-icon;
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .align-items-center;
  }

  .gjs-sm-icon[for='text-align-left'],
  .gjs-sm-icon[for='align-left'] {
    &:before {
      @include sv-fontawesome('\f036');
    }
  }

  .gjs-sm-icon[for='text-align-center'],
  .gjs-sm-icon[for='align-center'] {
    &:before {
      @include sv-fontawesome('\f037');
    }
  }

  .gjs-sm-icon[for='text-align-right'],
  .gjs-sm-icon[for='align-right'] {
    &:before {
      @include sv-fontawesome('\f038');
    }
  }

  .gjs-sm-icon[for='text-align-justify'],
  .gjs-sm-icon[for='align-justify'] {
    &:before {
      @include sv-fontawesome('\f039');
    }
  }

  .gjs-sm-icon[for='text-decoration-none'] {
    &:before {
      @include sv-fontawesome('\f87d');
    }
  }

  .gjs-sm-icon[for='text-decoration-underline'] {
    &:before {
      @include sv-fontawesome('\f0cd');
    }
  }

  .gjs-sm-icon[for='text-decoration-line-through'] {
    &:before {
      @include sv-fontawesome('\f0cc');
    }
  }

  .gjs-radio-item input+.gjs-radio-item-label {
    border-width: 0;
  }

  .gjs-radio-item input:checked+.gjs-radio-item-label {
    @extend.btn, .sv-btn-active;
  }

  .gjs-sm-sector .gjs-sm-field.gjs-sm-composite,
  .gjs-clm-tags .gjs-sm-field.gjs-sm-composite,
  .gjs-sm-sector .gjs-sm-composite.gjs-clm-field,
  .gjs-clm-tags .gjs-sm-composite.gjs-clm-field,
  .gjs-sm-sector .gjs-sm-composite.gjs-clm-select,
  .gjs-clm-tags .gjs-sm-composite.gjs-clm-select {
    @extend .bg-primary10;
    border: 0;
  }

  .sp-picker-container,
  .sp-palette-container {
    margin-bottom: 0;
    padding-bottom: 10px;
  }
}

.sv-sidebar-btn-sc-mc-item {
  @extend .gjs-radio-item;
}

.sv-sidebar-btn-sc-mc-input {
  @extend .gjs-sm-radio;
}

.sv-sidebar-btn-sc-mc-label {
  @extend .gjs-radio-item-label;
}