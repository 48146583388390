.pt-projects-table {
  .dx-datagrid .dx-datagrid-headers .dx-header-row td.sv-grid-cell,
  .dx-datagrid .dx-datagrid-headers .dx-header-row{
    background-color: transparent;
    border-bottom: 2px solid $sv-color-secondary40;
    font-weight:bold;
  }
  .dx-datagrid tr:not(.dx-row-alt):not(.dx-header-row) > td, .dx-datagrid tr:not(.dx-row-alt):not(.dx-header-row) > tr > td{
    background-color: #f5f5f5;
    border-top: 1px solid #f5f5f5;
  }
  .dx-datagrid .dx-row-alt > td, .dx-datagrid .dx-row-alt > tr > td{
    background-color: transparent;
    border-top: 0;
  }
  .dx-datagrid .dx-datagrid-headers .dx-header-row td.sv-grid-cell[aria-sort=ascending]:after,
  .dx-datagrid .dx-datagrid-headers .dx-header-row td.sv-grid-cell[aria-sort=descending]:after
  {
    color: $success;
  }
}
.pt-navigation-heading{
  @extend .h1;
  color: $sv-color-neutral;
  text-align: center;
  font-weight: bold;
}
.pt-navigation-lead{
  font-size: 1.75rem;
  color: $sv-color-neutral;
  text-align: center;
}
.pt-navigation-background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(/images/pt-navigation-background.jpg);
  background-size: cover;
  background-position: top left;
  min-height: calc(100vh - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.pt-navigation-items-list{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .pt-navigation-item{
    background-color: fade-out($sv-color-secondary10, 0.1);
    padding: 2rem;
    border: 1px solid $sv-color-secondary40;
    min-width: 320px;
    max-width: 320px;
    &:first-child{
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    .pt-nav-item-icon {
      font-size: 3.5rem;
      color: $success;
      text-align: center;
      height: 85px;
    }
    .pt-nav-text {
      font-weight: bold;
      text-align: center;
    }
    .pt-nav-text-info{
      text-align: center;
    }
    &:not(.pt-item-list-no-action):hover{
      cursor: pointer;
      .pt-nav-item-icon {
        font-size: 4rem;
        color: $success;
        text-align: center;
      }
    }
  }
    &.pt-item-list-small{
    .pt-navigation-item{
      padding: 1rem;
      min-width: 280px;
      max-width: 280px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 4px;
      .pt-nav-item-icon {
        font-size: 2rem;
        height: 50px;
      }
      
    &:not(.pt-item-list-no-action):hover{
      .pt-nav-item-icon {
        font-size: 2.5rem;
      }
    }
    }
  }
}
.pt-navigation-content-area{
  margin-top: 5rem;
  margin-bottom: 2rem;
  padding: 2rem;
  background-color: fade-out($sv-color-secondary10, 0.1);
  border-radius: 6px;
  border: 1px solid $sv-color-secondary40;

}

.pt-tiles-list{
  display: flex;
  flex-wrap: wrap;
  .pt-tile{
    border-radius: 6px;
    min-width: 450px;
    max-width: 450px;
    min-height: 280px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 1.75rem;
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    .pt-tile-title{
      @extend .h4;
      font-weight: bold;
    }
    .pt-tile-favorite {
      position: absolute;
      top: 20px;
      right: 20px;
      height: 30px;
      width: 30px;
      svg path{
        fill: transparent;
        stroke: $success;
      }
      &.pt-tile-favorite-active svg path{
        fill: $success;
        stroke: $success;
      }
      &:hover svg path{
        fill: $success;
        stroke: $success;
        cursor: pointer;
      }
    }
    .pt-tile-footer{
      width: 100%;
      align-self:flex-end;
      display: flex;
      justify-content: space-between;
      .pt-tile-footer-btn-cta{
        @extend .btn;
        @extend .btn-success;
        width: 30%;
        min-width: 200px;
        max-width: 500px;
      }
      .pt-tile-footer-btn-light {
        @extend .btn;
        @extend .btn-light;
        min-width: 44px;
      }
    }
  }
}
.pt-page-container {
  margin-top: 2rem;
}

.pt-main-layout-row {
  margin-top: 2rem;

  header {

    padding-left: 6.25rem;
    padding-right: 6.25rem;

    @media (max-width: 1200px) {
      & {
        padding-left: 3.25rem;
        padding-right: 3.25rem;
      }
    }
  }

  & > section {
    overflow-x: hidden;
    display: flex;

    @media (max-width: 1100px) {
      & {

        height: calc(100vh - 7.4rem);
        overflow-x: hidden;
        display: block;
      }
    }

    .w10of10 {
      flex: 0 0 100%;
      max-width: 100%;
      padding-left: 6.25rem;
      padding-right: 6.25rem;
      height: calc(100vh - 9.4rem);
      overflow-x: hidden;

      @media (max-width: 1200px) {
        & {
          padding-left: 3.25rem;
          padding-right: 3.25rem;
        }
      }

      @media (max-width: 1100px) {
        & {
          padding-right: 3.25rem;
          height: auto;
          overflow-x: auto;
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }

    .w3of10 {
      flex: 0 0 30%;
      max-width: 30%;
      padding-left: 6.25rem;
      padding-right: .5rem;
      height: calc(100vh - 9.4rem);
      overflow-x: hidden;
      direction: rtl;

      * {
        direction: ltr;
      }

      @media (max-width: 1200px) {
        & {
          padding-left: 3.25rem;
        }
      }

      @media (max-width: 1100px) {
        & {
          padding-right: 3.25rem;
          height: auto;
          direction: ltr;
          overflow-x: auto;
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }

    .w7of10 {
      flex: 0 0 70%;
      max-width: 70%;
      padding-right: 6.25rem;
      padding-left: .5rem;
      overflow-x: hidden;
      height: calc(100vh - 9.4rem);

      @media (max-width: 1200px) {
        & {
          padding-right: 3.25rem;
        }
      }

      @media (max-width: 1100px) {
        & {
          padding-left: 3.25rem;
          flex: 0 0 100%;
          max-width: 100%;
          height: auto;
          overflow-x: auto;
        }
      }
    }
  }
}

.pt-nav-row-1, .pt-nav-row-2{
  padding-top: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
}
.pt-nav-row-1 {
  font-size: 1rem;
}
.pt-nav-row-2 {
  font-size: 1.25rem;
}
 
 @media (min-width: 992px){
  .pt-navbar .pt-language-selector-container {
    margin-left: auto;
  }

  .pt-navbar .show .nav-item, 
  .navbar-light .collapsing .nav-item {
    border-bottom: 1px solid #ccc;
    padding-left: 5px;
    padding-right: 5px;
  }
  .pt-navbar .show .nav-item.pt-language-selector-container, 
  .navbar-light .collapsing .nav-item.pt-language-selector-container {
    padding: 5px;
  }
 }