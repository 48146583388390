$popover-header-color: $sv-color-neutral !default;
$popover-header-bg: $secondary !default;
$popover-border-color: $secondary !default;
$popover-border-width: 0.15rem !default;
$popover-max-width: 100% !default;
$popover-border-radius: 0.375rem;

.sv-popover {

    &.sv-popover-xs {
        .popover-body {
            max-width: 20vw;
        }
    }

    &.sv-popover-small {
        .popover-body {
            max-width: 33.33vw;
        }
    }

    &.sv-popover-medium {
        .popover-body {
            max-width: 50vw;
        }
    }

    &.sv-popover-big {
        .popover-body {
            max-width: 75vw;
        }
    }

    .sv-popover-header {
        @extend .popover-header;

        .sv-popover-close {
            color: $popover-header-color;
            font-size: 1rem;
            float: right;

            &:hover {
                cursor: pointer;
                opacity: 0.8;
            }
        }
    }

    .sv-popover-footer {
        @extend .popover-body;

        .sv-btn-confirm,
        .sv-btn-reject {
            @extend .sv-btn;

            &.danger {
                @extend .btn-danger;
            }

            &.success {
                @extend .btn-success;
            }

            &.info {
                @extend .btn-info;
            }

            &.light {
                @extend .btn-light;
            }
        }

        .sv-btn-container {
            display: flex;
            flex-direction: row-reverse;

            button {
                margin-left: 0.5rem;
                padding: .3125rem .625rem;
            }
        }
    }
}

.content-preview-popover {
    max-width: 350px;
    margin-right: 20px;

    img {
        max-width: 100%;
    }
}