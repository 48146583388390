.view-size {
  position: relative;
  height: 2.75rem;
  margin-bottom: 3.125rem;

  .view-size-block {
    position: absolute;
    left: 50%;
    -moz-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);

    .view-size-label {
      display: inline-block;
      line-height: 2.75rem;
      font-size: 0.8125rem;

      &.left {
        margin-left: 3.125rem;
      }

      &.right {
        margin-right: 3.125rem;
      }
    }

    .arrow {
      position: absolute;
      top: 0;
      width: 0;
      height: 0;
      border-style: solid;

      &.left {
        border-width: 1.375rem 0 1.375rem 0.91666667rem;
        border-color: transparent transparent transparent #444342;
      }

      &.right {
        border-width: 1.375rem 0.91666667rem 1.375rem;
        border-color: transparent #444342 transparent transparent;
      }
    }

    &.active {
      background-color: #CCDBE3 !important;
    }

    &.view-size-lg {
      width: 48rem;
      background-color: #E0E5E7;
      height: 2.75rem;
      cursor: pointer;

      .arrow {
        &.left {
          border-left-color: #D9DFE1;
          left: 0;
        }

        &.right {
          border-right-color: #D9DFE1;
          right: 0;
        }
      }
    }

    &.view-size-md {
      width: 20rem;
      background-color: #E0E5E7;
      height: 2.75rem;
      cursor: pointer;

      &.active {
        .arrow {
          &.left {
            border-left-color: #E0E5E7 !important;
          }

          &.right {
            border-right-color: #E0E5E7 !important;
          }
        }
      }

      .arrow {
        &.left {
          border-left-color: #CCDBE3;
          left: 0;
        }

        &.right {
          border-right-color: #CCDBE3;
          right: 0;
        }
      }
    }

    &.view-size-device {
      background-color: #fff;
      height: 100%;
      width: 5rem;

      .device {
        position: absolute;
        left: 50%;
        top: 50%;
        -moz-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        color: #004a70;
        font-size: 1.25rem;
      }

      .arrow {
        &.left {
          border-left-color: #E0E5E7;
          left: 0;

          &.active {
            border-left-color: #CCDBE3 !important;
          }
        }

        &.right {
          border-right-color: #E0E5E7;
          right: 0;

          &.active {
            border-right-color: #CCDBE3 !important;
          }
        }
      }
    }
  }
}

.sv-survey-layout-row {

  //probably should be chaged on absolute position
  .mobile-survey-view {
    height: 880px;
    width: 555px;
    padding: 70px 70px 0 90px;
    margin-left: auto;
    margin-right: auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(/assets/design/img/smartphone_background.png);

    .survey-content {
      height: 667px;
      width: 375px;
      overflow-y: auto;
    }
  }
}

.code-mirror-container {
  border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  -webkit-border-radius: 10px 10px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #CCCCCC;
  overflow: hidden;
}

:host ::ng-deep .CodeMirror {
  height: auto;
}